import React from "react";
import { Table } from "antd";

interface Comments {
  dataSource: any;
  columns: any;
  title: string;
}

const Comments = React.memo(({ dataSource, columns, title }: Comments) => {
  return (
    <section className="board full-width full-width-body">
      <header className="board-header">
        <h2>{title}</h2>
      </header>

      <div className="board-body">
        <Table
          dataSource={dataSource}
          columns={columns}
          pagination={{ hideOnSinglePage: true }}
        />
      </div>
    </section>
  );
});

export default Comments;
