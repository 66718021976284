import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Reaction {
  id: number;
  code: string;
  name: string;
}

interface ReactionData {
  reaction: Reaction;
  value: number;
}

interface ReactionChartState {
  isLoading: boolean;
  reactedChartsPercents: ReactionData[];
  includeUsers: {
    ids: number[];
  };
  includeQuestions: {
    sectionIds: number[];
    // questionIds: any[];
  };
  excludeUsers: {
    ids: number[];
  };
  error: string;
}

const initialState: ReactionChartState = {
  isLoading: false,
  reactedChartsPercents: [],
  includeUsers: {
    ids: [],
  },
  includeQuestions: {
    sectionIds: [],
    // questionIds: [],
  },
  excludeUsers: {
    ids: [],
  },
  error: "",
};

export const getReactionChartPercents = createAsyncThunk(
  "getReactionChartPercents",
  async (id: number, { getState }) => {
    try {
      const state = (getState() as { reactedChartsCounts: ReactionChartState })
        .reactedChartsCounts;
      const { includeUsers, includeQuestions } = state;
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/reaction/chart/percents`,
        { includeUsers, includeQuestions }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const reactionChartsPercentsSlice = createSlice({
  initialState,
  name: "reactionChartPercents",
  reducers: {
    setIncludeUserReactionChartPercents: (state, { payload }) => {
      state.includeUsers.ids = payload;
    },
    setIncludeSectionsReactionChartPercents: (state, { payload }) => {
      state.includeQuestions.sectionIds = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReactionChartPercents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReactionChartPercents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.reactedChartsPercents = action.payload.data;
      })
      .addCase(getReactionChartPercents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});

const { actions } = reactionChartsPercentsSlice;

export const {
  setIncludeUserReactionChartPercents,
  setIncludeSectionsReactionChartPercents,
} = actions;
