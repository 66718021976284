import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Emoji {
  code: string;
  emotionalTone: number;
  id: number;
  name: string;
}
interface EmojiSlice {
  isLoading: boolean;
  emoji: Emoji[];
  error: string;
}

const initialState: EmojiSlice = {
  isLoading: false,
  emoji: [],
  error: "",
};

export const getEmoji = createAsyncThunk("getEmoji", async (id: number) => {
  try {
    const response = await axiosInstance.get(
      `/v1/reports/boards/${id}/reaction/available`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export const emojiSlice = createSlice({
  initialState,
  name: "emoji",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmoji.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEmoji.fulfilled, (state, action) => {
        state.isLoading = false;
        state.emoji = action.payload;
      })
      .addCase(getEmoji.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
