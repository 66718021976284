import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstanceWithoutApi } from "utils/api";

interface ExportPdfSlice {
  isLoading: boolean;
  isDisabled: boolean;
  error: string;
}

const initialState: ExportPdfSlice = {
  isLoading: false,
  isDisabled: false,
  error: "",
};

export const getExportPdf = createAsyncThunk(
  "getExportPdf",
  async (boardId: number) => {
    try {
      const response = await axiosInstanceWithoutApi.get(
        `/v1/reports/boards/${boardId}/export/pdf`,
        {
          responseType: "blob",
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const exportPdfSlice = createSlice({
  initialState,
  name: "exportPdf",
  reducers: {
    updateStateForDisabled: (state, { payload }) => {
      state.isDisabled = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExportPdf.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExportPdf.fulfilled, (state, action) => {
        state.isLoading = false;

        const blob = new Blob([action.payload], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report.pdf`);
        document.body.appendChild(link);
        link.click();

        link.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .addCase(getExportPdf.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});

const { actions } = exportPdfSlice;

export const { updateStateForDisabled } = actions;

export default exportPdfSlice.reducer;
