import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";
interface Section {
  id: number;
}

interface Score {
  positive: number;
  neutral: number;
  negative: number;
  total: number;
}

interface SentimentChartData {
  section: Section;
  score: Score;
}

interface SentimentChartState {
  isLoading: boolean;
  sentimentChartSections: SentimentChartData[];
  includeUsers: {
    ids: number[];
  };
  excludeUsers: {
    ids: number[];
  };
  error: string;
}

const initialState: SentimentChartState = {
  isLoading: false,
  sentimentChartSections: [],
  includeUsers: {
    ids: [],
  },
  excludeUsers: {
    ids: [],
  },
  error: "",
};

export const getSentimentChartSections = createAsyncThunk(
  "sentimentChartSections/getSentimentChartSections",
  async (id: number, { getState }) => {
    try {
      const state = (
        getState() as { sentimentChartSections: SentimentChartState }
      ).sentimentChartSections;
      const { includeUsers } = state;
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/sentiment/chart/sections`,
        { includeUsers }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const sentimentChartSectionsSlice = createSlice({
  initialState,
  name: "sentimentCharSections",
  reducers: {
    setIncludeUser: (state, { payload }) => {
      state.includeUsers.ids = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSentimentChartSections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSentimentChartSections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sentimentChartSections = action.payload;
      })
      .addCase(getSentimentChartSections.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
const { actions } = sentimentChartSectionsSlice;

export const { setIncludeUser } = actions;
