import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface updateReport {
  isLoading: boolean;
  error: string;
}

const initialState: updateReport = {
  isLoading: false,
  error: "",
};

export const updateReport = createAsyncThunk(
  "updateReport",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/sentiment`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateReportSlice = createSlice({
  initialState,
  name: "updateReport",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReport.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
