import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface QuestionComment {
  body: string;
  controversy: number;
  createdOn: Date;
  id: number;
  replyCount: number;
  sentimentScore: number;
}

interface CommentsSlice {
  isLoading: boolean;
  questions: QuestionComment[];
  error: string;
}

const initialState: CommentsSlice = {
  isLoading: false,
  questions: [],
  error: "",
};

export const getQuestionsComments = createAsyncThunk(
  "getControversialQuestions",
  async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/v1/reports/boards/${id}/sentiment/controversial-questions`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const quetsionsSlice = createSlice({
  initialState,
  name: "board",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsComments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestionsComments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.questions = action.payload;
      })
      .addCase(getQuestionsComments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
