//Copied (then modified) from the portalapi project, as an example

//export * from './app.config';

export const apiUrl = 'https://boardsapistaging.visionslive.com/';
export const vlqrApiUrl = 'https://apistaging.visionslive.com/VL';
export const clientUrl = 'https://clientstaging.visionslive.com';

//export const changeCrabProjectId = 'XXXXazUgO1';
//export const changeCrabUrl = 'https://visionslivestaging.changecrab.com/';

//export const growthbookApiKey = 'XXXXsdk-hgLglYXECUeU4Fu1';
//export const growthbookSecretKey = 'XXXXzbfQUZrXcfZGamMpsVEVqQ==';