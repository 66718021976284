import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Empty } from "antd";
import { useSelector } from "react-redux";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ReactionBar = ({ reactionsCounts }: any) => {
  const availableEmoji = useSelector((state: any) => state.emoji.emoji);

  const dataValues = reactionsCounts?.map((item: any) => {
    return item.value ? item.value : [];
  });

  const maxValue = 0.5;

  const options: any = {
    indexAxis: "y",
    layout: {},
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        grace: maxValue,
        position: "bottom",
        grid: {
          display: false,
        },
      },
      y: {
        position: "left",
        ticks: {
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const colors = [
    { emotionalTone: 100, background: "#288C458C" },
    { emotionalTone: 50, background: "#66BD228C" },
    { emotionalTone: 0, background: "#FFD6008C" },
    { emotionalTone: -50, background: "#FF480E8C" },
    { emotionalTone: -100, background: "#BD00068C" },
  ];
  const backgrounds = colors
    .map((item: any) => {
      const match = availableEmoji.find(
        (el: any) => item.emotionalTone === el.emotionalTone
      );
      return match ? item.background : null;
    })
    .filter((background) => background !== null);

  const sortedLabels = [...availableEmoji].sort((a: any, b: any) => {
    return b.emotionalTone - a.emotionalTone;
  });

  const data = {
    labels: sortedLabels
      .filter((item: any) => {
        return reactionsCounts.find((el: any) => el.reaction.id === item.id);
      })
      .map((item: any) => item.name),
    datasets: [
      {
        data: dataValues,
        backgroundColor: backgrounds,
        borderWidth: 1,
        barPercentage: 0.8,
        barThickness: 40,
      },
    ],
  };

  const sortedEmojiArray = [...availableEmoji].sort((a: any, b: any) => {
    return b.emotionalTone - a.emotionalTone;
  });
  return (
    <div
      className="display-flex flex-direction-column flex-1"
      style={{ gap: "24px" }}
    >
      <div className="reactions-grid">
        {sortedEmojiArray?.map((emoji: any, key: number) => {
          return (
            <div className="reactions-grid-item" key={key}>
              <span className="emoji-icon">
                {sortedEmojiArray.length > 0
                  ? String.fromCodePoint(
                      ...(emoji?.code?.split(",").map(Number) as any)
                    )
                  : ""}
              </span>
              <span>{emoji.name}</span>
            </div>
          );
        })}
      </div>
      <section className="board">
        <header className="board-header">Reaction Count by Board</header>
        <div className="board-body">
          {dataValues.length > 0 ? (
            <div style={{ maxWidth: "600px", flex: "1 1 600px" }}>
              <Bar options={options} data={data} />
            </div>
          ) : (
            <Empty></Empty>
          )}
        </div>
      </section>
    </div>
  );
};

export default ReactionBar;
