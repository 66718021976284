import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Message {
  id: number;
  user: {
    id: number;
  };
  body: string;
}

interface KeyPhraseSlice {
  isLoading: boolean;
  keyPhraseMessage: Message[];
  error: string;
}

const initialState: KeyPhraseSlice = {
  isLoading: false,
  keyPhraseMessage: [],
  error: "",
};

export const getKeyPhraseMessage = createAsyncThunk(
  "getKeyPhraseMessage",
  async ({ id, keyId }: { id: number; keyId: number }) => {
    try {
      const response = await axiosInstance.get(
        `/v1/reports/boards/${id}/key-phrases/${keyId}/messages`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const keyPhraseMessageSlice = createSlice({
  initialState,
  name: "keyPhrase",
  reducers: {
		clearMessage: (state, { payload }) => {
      state.keyPhraseMessage = payload;
    },
	},
  extraReducers: (builder) => {
    builder
      .addCase(getKeyPhraseMessage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getKeyPhraseMessage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.keyPhraseMessage = action.payload;
      })
      .addCase(getKeyPhraseMessage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});

const { actions } = keyPhraseMessageSlice;

export const {
	clearMessage
} = actions;
