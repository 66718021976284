// api/v1/boards/{boardId}/respondents/heatmap
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Respondent {
  id: number;
  firstName: string;
  lastName: string;
}

interface RespondentsSlice {
  isLoading: boolean;
  respondents: Respondent[];
  error: string;
}

const initialState: RespondentsSlice = {
  isLoading: false,
  respondents: [],
  error: "",
};

export const getHetmapRespondents = createAsyncThunk(
  "getHeatmapRespondents",
  async (boardId: number) => {
    try {
      const response = await axiosInstance.get(
        `/v1/boards/${boardId}/respondents/heatmap`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const heatmapRespondentsSlice = createSlice({
  initialState,
  name: "heatmapRespondents",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHetmapRespondents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHetmapRespondents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.respondents = action.payload;
      })
      .addCase(getHetmapRespondents.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
