import React from "react";
import { Button } from "antd";
import {
  getExportPdf,
  updateStateForDisabled,
} from "store/slices/exportPdf/exportPdf";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ExportPdf = React.memo(() => {
  const { id } = useParams();
  const pdfLoading = useSelector((state: any) => state.exportPdf);
  const dispatch = useDispatch<any>();

  const handleClickExportPdf = async () => {
    dispatch(updateStateForDisabled(true));
    await dispatch(getExportPdf(Number(id)));
    dispatch(updateStateForDisabled(false));
  };
  return (
    <>
      <Button
        type="primary"
        loading={pdfLoading.isLoading}
        disabled={pdfLoading.isDisabled}
        style={{ marginLeft: "15px" }}
        onClick={handleClickExportPdf}
      >
        Export PDF
      </Button>
    </>
  );
});

export default ExportPdf;
