import React, { useEffect } from "react";
import CollapseForHeatmap from "Components/CollapseForHeatmap/CollapseForHeatmap";
import { useDispatch, useSelector } from "react-redux";
import { getHeatmapSections } from "store/slices/heatmapReport/heatmapSections";
import { getHeatmapQuestions } from "store/slices/heatmapReport/heatmapQuestions";
import { useParams } from "react-router-dom";
import { getHetmapRespondents } from "store/slices/heatmapReport/heatmapRespondents";

const Heatmap = () => {
  const { id } = useParams();
  const dispatch = useDispatch<any>();
  const heatmapSections = useSelector(
    (state: any) => state.heatmapSection.heatmapSections
  );
  const heatmapQuestions = useSelector(
    (state: any) => state.heatmapQuestions.heatmapQuestions
  );

  useEffect(() => {
    dispatch(getHeatmapSections(Number(id)));
    dispatch(getHeatmapQuestions(Number(id)));
    dispatch(getHetmapRespondents(Number(id)));
  }, []);
  return (
    <>
      <div className="board-set">
        <CollapseForHeatmap
          heatmapData={heatmapSections}
          heatmapQuestionsData={heatmapQuestions}
        />
      </div>
    </>
  );
};

export default Heatmap;
