import React from "react";
import AppRouter from "routes/Routes";
import { Provider } from "react-redux";
import { store } from "store/store";
import Notification from "Components/Notification/Notification";

import "./App.scss";

const App = React.memo(() => {
  return (
    <>
      <Provider store={store}>
        <AppRouter />
        <Notification />
      </Provider>
    </>
  );
});

export default App;
