import React, { useEffect, useState } from "react";
// import DropDownWithCollapse from "Components/DropDown/DropDownWithCollapse";
import DropDown from "Components/DropDown/DropDown";
import StatisticBoard from "Components/StatisticBoard/StatisticBoard";
import Comments from "Components/Comments/Comments";
import { Button, Form, Popover, Progress } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import TextToggle from "../TextToggle/TextToggle";
import { getReactedPositiveComments } from "store/slices/reacted/positiveReactedSlice";
import { getReactedNegativeComments } from "store/slices/reacted/negativeReactedSlice";
import { getReactedQuestionsComments } from "store/slices/reacted/questionsReactedSlice";
import { getSectionsAndQuestions } from "store/slices/sections/sectionsSlice";
import { getRespondents } from "store/slices/respondents/respondentsSlice";
import {
  setIncludeUserReactionChartCounts,
  getReactionChartCounts,
  setIncludeSectionsReactionChartCounts,
} from "store/slices/reactionCharts/reactionChartsCounts";
import {
  getReactionChartPercents,
  setIncludeUserReactionChartPercents,
  setIncludeSectionsReactionChartPercents,
} from "store/slices/reactionCharts/reactionChartsPercents";
import { getEmoji } from "store/slices/emoji/emojiSlice";

const ResponsesAnalysis = () => {
  const dispatch = useDispatch<any>();
  const { id } = useParams();

  const [selectedNicknames, setSelectedNicknames] = useState<any>([]);
  const [selectedSections, setSelectedSections] = useState<any>([]);
  // const [sectionsData, setSectionsData] = useState<any>([]);
  // const [respondentsData, setRespondentsData] = useState<any>([]);
  const board = useSelector((state: any) => state.board.board);

  const respondents = useSelector(
    (state: any) => state.respondents.respondents
  );

  const positiveReactedComments = useSelector(
    (state: any) => state.reactedPositiveComments.positiveReactedComments
  );
  const negativeReactedComments = useSelector(
    (state: any) => state.reactedNegativeComments.negativeReactedComments
  );
  const quetsionsReactedComments = useSelector(
    (state: any) => state.reactedQuetsionsComments.questionsReactedComments
  );

  const reactionsCounts = useSelector(
    (state: any) => state.reactedChartsCounts.reactedChartsCounts
  );

  const sectionsAndQuestions = useSelector(
    (state: any) => state.sectionsAndQuestions.sectionsAndQuestions
  );

  const reactionChartPercents = useSelector(
    (state: any) => state.reactionChartsPercents.reactedChartsPercents
  );

  const availableEmoji = useSelector((state: any) => state.emoji.emoji);

  useEffect(() => {
    if (board.name && sectionsAndQuestions.length === 0) {
      dispatch(getSectionsAndQuestions(Number(id)));
    }
    // setSectionsData(sectionsAndQuestions);
  }, [board.name]);

  useEffect(() => {
    if (board.name && respondents.length === 0) {
      dispatch(getRespondents(Number(id)));
    }
  }, [board.name]);

  useEffect(() => {
    if (board.name) {
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    }
  }, [board.name]);

  useEffect(() => {
    if (board.name) {
      dispatch(getReactedPositiveComments(Number(id)));
      dispatch(getReactedNegativeComments(Number(id)));
      dispatch(getReactedQuestionsComments(Number(id)));
      dispatch(getEmoji(Number(id)));
    }
  }, [board.name]);

  // const handleAction = (record: any) => console.log(record);
  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (record: any) => {
        const name = respondents.find((item: any) => item?.id === record?.id);
        return <>{name ? name.firstName + " " + name.lastName : ""}</>;
      },
    },
    {
      title: "Message",
      dataIndex: "body",
      key: "body",
      render: (record: any) => <TextToggle text={record} />,
    },
    {
      title: "Reaction count",
      dataIndex: "reactions",
      key: "reactions",
      render: (record: any) => {
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {record?.map((item: any, index: any) => {
              return (
                <span
                  key={index}
                  style={{ display: "inline-block", marginRight: 5 }}
                >
                  {availableEmoji?.length > 0
                    ? String.fromCodePoint(
                        ...(availableEmoji
                          ?.find((el: any) => item?.reaction?.id === el.id)
                          ?.code.split(",")
                          .map((numString: any) => Number(numString)) as any)
                      )
                    : ""}

                  {item.value}
                </span>
              );
            })}
          </div>
        );
      },
    },
    // {
    //   title: "Avg Reaction Score",
    //   dataIndex: "avg_reaction_score",
    //   key: "avg_reaction_score",
    // },
    {
      title: "Avg Reaction Score",
      dataIndex: "reactionScore",
      key: "reactionScore",
      render: (record: any) => (
        <Progress
          percent={record * 100}
          format={(percent: any) => `${parseInt(percent)}%`}
          strokeColor={"#66BD228C"}
          trailColor={"transparent"}
          strokeLinecap="butt"
          size={[120, 24]}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "url",
      key: "actions",
      render: (record: any) => (
        <Popover content={() => actionButtonPopoverTpl(record)} trigger="click">
          <Button
            type="primary"
            icon={<EllipsisOutlined />}
            // onClick={() => handleAction(record)}
          ></Button>
        </Popover>
      ),
    },
  ];

  const questionsColumns = [
    {
      title: "Message",
      dataIndex: "body",
      key: "body",
      render: (record: any) => <TextToggle text={record} />,
    },
    {
      title: "Reply Count",
      dataIndex: "replyCount",
      key: "replyCount",
      render: (record: any) => (
        <span style={{ marginLeft: "30px" }}>{record}</span>
      ),
    },
    {
      title: "Avg Sentiment Score",
      dataIndex: "sentimentScore",
      key: "sentimentScore",
      render: (record: any) => (
        <Progress
          style={{ marginLeft: "30px" }}
          percent={record * 100}
          format={(percent: any) => `${parseInt(percent)}%`}
          strokeColor={"#FFD600"}
          trailColor={"#ddd"}
          strokeLinecap="butt"
          size={[120, 24]}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "url",
      key: "actions",
      render: (record: any) => (
        <Popover content={() => actionButtonPopoverTpl(record)} trigger="click">
          <Button
            type="primary"
            icon={<EllipsisOutlined />}
            // onClick={() => handleAction(record)}
          ></Button>
        </Popover>
      ),
    },
  ];
  const actionButtonPopoverTpl = (record: any) => (
    <Link to={record} target="_blank">
      <Button icon={<EyeOutlined />}>View</Button>
    </Link>
  );

  const negativeColumns = columns.map((column) => {
    if (column.key === "reactionScore") {
      return {
        ...column,
        render: (record: any) => (
          <Progress
            percent={record * 100}
            format={(percent: any) => `${parseInt(percent)}%`}
            strokeColor={"#FF480E8C"}
            trailColor={"#ddd"}
            strokeLinecap="butt"
            size={[120, 24]}
          />
        ),
      };
    }
    return column;
  });

  const handleCheckboxChange = (e: any, nickname: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedNicknames = [...selectedNicknames, nickname.id];
      setSelectedNicknames(newSelectedNicknames);
      dispatch(setIncludeUserReactionChartCounts(newSelectedNicknames));
      dispatch(setIncludeUserReactionChartPercents(newSelectedNicknames));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    } else {
      const newSelectedNicknames = selectedNicknames.filter(
        (el: any) => el !== nickname.id
      );
      setSelectedNicknames(newSelectedNicknames);
      dispatch(setIncludeUserReactionChartCounts(newSelectedNicknames));
      dispatch(setIncludeUserReactionChartPercents(newSelectedNicknames));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    }
  };

  const handleSelectAll = (e: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const respondentIds = respondents.map((respondent: any) => respondent.id);
      setSelectedNicknames(respondentIds);
      dispatch(setIncludeUserReactionChartCounts(respondentIds));
      dispatch(setIncludeUserReactionChartPercents(respondentIds));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    } else {
      setSelectedNicknames([]);
      dispatch(setIncludeUserReactionChartCounts([]));
      dispatch(setIncludeUserReactionChartPercents([]));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    }
  };

  const handleCheckboxChangeSections = (e: any, nickname: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedSections = [...selectedSections, nickname.id];
      setSelectedSections(newSelectedSections);
      dispatch(setIncludeSectionsReactionChartCounts(newSelectedSections));
      dispatch(setIncludeSectionsReactionChartPercents(newSelectedSections));
      dispatch(setIncludeUserReactionChartCounts(selectedNicknames));
      dispatch(setIncludeUserReactionChartPercents(selectedNicknames));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    } else {
      const newSelectedSections = selectedSections.filter(
        (el: any) => el !== nickname.id
      );
      setSelectedSections(newSelectedSections);
      dispatch(setIncludeSectionsReactionChartCounts(newSelectedSections));
      dispatch(setIncludeSectionsReactionChartPercents(newSelectedSections));
      dispatch(setIncludeUserReactionChartCounts(selectedNicknames));
      dispatch(setIncludeUserReactionChartPercents(selectedNicknames));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    }
  };

  const handleSelectAllSections = (e: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const sectionsId = sectionsAndQuestions.map(
        (respondent: any) => respondent.id
      );
      setSelectedSections(sectionsId);
      dispatch(setIncludeSectionsReactionChartCounts(sectionsId));
      dispatch(setIncludeSectionsReactionChartPercents(sectionsId));
      // dispatch(setIncludeSectionsSentimentQuestions(sectionsId));
      dispatch(setIncludeUserReactionChartCounts(selectedNicknames));
      dispatch(setIncludeUserReactionChartPercents(selectedNicknames));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    } else {
      setSelectedSections([]);
      dispatch(setIncludeSectionsReactionChartCounts([]));
      dispatch(setIncludeSectionsReactionChartPercents([]));
      dispatch(setIncludeUserReactionChartPercents(selectedNicknames));
      dispatch(setIncludeUserReactionChartCounts(selectedNicknames));
      dispatch(getReactionChartCounts(Number(id)));
      dispatch(getReactionChartPercents(Number(id)));
    }
  };

  const resetFilters = () => {
    dispatch(setIncludeSectionsReactionChartCounts([]));
    dispatch(setIncludeSectionsReactionChartPercents([]));
    dispatch(setIncludeUserReactionChartPercents([]));
    dispatch(setIncludeUserReactionChartCounts([]));
    dispatch(getReactionChartCounts(Number(id)));
    dispatch(getReactionChartPercents(Number(id)));
    setSelectedSections([]);
    setSelectedNicknames([]);
  };

  return (
    <>
      <div className="filters">
        <Form name="basic" layout="vertical">
          <Form.Item<any> label="View" name="view">
            {/* <DropDownWithCollapse title={"All sections and questions"} /> */}
            <DropDown
              title={"All Sections"}
              handleCheckboxChange={handleCheckboxChangeSections}
              handleSelectAll={handleSelectAllSections}
              data={sectionsAndQuestions}
              selectedData={selectedSections}
            />
          </Form.Item>
          <Form.Item<any> label="Respondent" name="respondent">
            <DropDown
              title={"All Users"}
              handleCheckboxChange={handleCheckboxChange}
              handleSelectAll={handleSelectAll}
              data={respondents}
              selectedData={selectedNicknames}
            />
          </Form.Item>
        </Form>
        <div>
          <Button onClick={resetFilters}>Reset Filters</Button>
        </div>
      </div>

      {/* <StatisticBoard tab={"responsesAnalysis"} /> */}
      <StatisticBoard
        tab={"responsesAnalysis"}
        sectionsData={sectionsAndQuestions}
        respondentsData={respondents}
        handleCheckboxChange={handleCheckboxChange}
        reactionsCounts={reactionsCounts}
        handleCheckboxChangeSections={handleCheckboxChangeSections}
        handleSelectAll={handleSelectAll}
        handleSelectAllSections={handleSelectAllSections}
        chart={reactionChartPercents}
        // chartSections={sentimentChartSections}
        // chartQuestions={sentimentChartQuestions}
        selectedSections={selectedSections}
        selectedNicknames={selectedNicknames}
        pieChartTitle="Overall Respondent Reaction"
      />
      <div className="board-set">
        <Comments
          dataSource={positiveReactedComments.map((comment: any) => ({
            ...comment,
            key: comment.id.toString(),
          }))}
          columns={columns}
          title={"Top-10 comments with positive reactions"}
        />
        <Comments
          dataSource={negativeReactedComments.map((comment: any) => ({
            ...comment,
            key: comment.id.toString(),
          }))}
          columns={negativeColumns}
          title={"Top-10 comments with negative reactions"}
        />
        <Comments
          dataSource={quetsionsReactedComments.map((comment: any) => ({
            ...comment,
            key: comment.id.toString(),
          }))}
          columns={questionsColumns}
          title={"Most controversial questions"}
        />
      </div>
    </>
  );
};

export default ResponsesAnalysis;
