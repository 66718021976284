import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
}

interface CurrentUserSlice {
  isLoading: boolean;
  user: User;
  error: string;
}
const initialState: CurrentUserSlice = {
  isLoading: false,
  user: { id: 0, firstName: "", lastName: "", email: "" },
  error: "",
};

export const getCurrentUser = createAsyncThunk("getCurrentUser", async () => {
  try {
    const response = await axiosInstance.get(`/v1/users/current`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const currentUserSlice = createSlice({
  initialState,
  name: "currentUser",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoading = false;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
