import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Board {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
}

interface BoardSlice {
  isLoading: boolean;
  board: Board;
  error: string;
}
const initialState: BoardSlice = {
  isLoading: false,
  board: { id: 0, name: "", startDate: "", endDate: "" },
  error: "",
};

export const getBoard = createAsyncThunk("getBoard", async (id: number) => {
  try {
    const response = await axiosInstance.get(`/v1/boards/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});
export const boardSlice = createSlice({
  initialState,
  name: "board",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBoard.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBoard.fulfilled, (state, action) => {
        state.board = action.payload;
        state.isLoading = false;
      })
      .addCase(getBoard.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
