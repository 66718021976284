import axios from "axios";
import { apiUrl } from "Config";

const axiosInstance = axios.create({
  baseURL: apiUrl + "api",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((request) => {
  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Response Error:", error);
    return Promise.reject(error);
  }
);

const axiosInstanceWithoutApi = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstanceWithoutApi.interceptors.request.use((request) => {
  return request;
});

axiosInstanceWithoutApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error("Response Error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { axiosInstanceWithoutApi };
