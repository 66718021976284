import React, { useEffect, useRef, useState, useCallback } from "react";
import { Chart, registerables } from "chart.js";
import { Button } from "antd";

import photoCamera from "svg/photocamera.svg";
import pins_circle from "svg/pins_circle.svg";
import mouse_circle from "svg/mouse_circle.svg";
Chart.register(...registerables);

const btnName = ["Original", "Pins", "Heatmap"];

const HeatmapWithImage = ({
  imageUrl,
  dataPoints,
  dataClusters,
  handleClickForPins,
  sectionPins,
  sectionId,
  selectedNicknames,
  selectedReactions,
}: any) => {
  const [imageState, setImageState] = useState<string[]>(["Original"]);
  const [pins, setPins] = useState<number[]>(sectionPins || []);
  const pinsRef = useRef<number[]>(pins);
  const chartRef = useRef<HTMLCanvasElement | null>(null) as any;
  const chartInstanceRef = useRef<any>(null);

  const handleClickStateImage = (state: string) => {
    if (state === "Original") {
      if (imageState.includes("Original")) {
        return;
      } else {
        setImageState(["Original"]);
      }
    } else {
      let newImageState = [...imageState];

      if (imageState.includes(state)) {
        newImageState = newImageState.filter((el: string) => el !== state);
      } else {
        newImageState.push(state);
      }

      if (newImageState.includes("Pins") || newImageState.includes("Heatmap")) {
        newImageState = newImageState.filter((el: string) => el !== "Original");
      }

      if (newImageState.length === 0) {
        newImageState.push("Original");
      }

      setImageState(newImageState);
    }
  };

  const initializeChart = () => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      const image = new Image();
      image.src = imageUrl;

      image.onload = () => {
        const scaleWidth = Math.min(1350, ctx.canvas.clientWidth);
        const scaleHeight = Math.min(772, ctx.canvas.clientHeight);
        const aspectRatio = ctx.canvas.clientWidth / ctx.canvas.clientHeight;
        let canvasWidth, canvasHeight: number;
        const coefHeight = scaleHeight / image.height;
        const coefWidth = scaleWidth / image.width;

        if (scaleWidth / aspectRatio <= scaleHeight) {
          canvasWidth = scaleWidth;
          canvasHeight = scaleWidth / aspectRatio;
        } else {
          canvasWidth = scaleHeight * aspectRatio;
          canvasHeight = scaleHeight;
        }

        chartRef.current.width = canvasWidth;
        chartRef.current.height = canvasHeight;

        const backgroundImagePlugin = {
          id: "backgroundImagePlugin",
          beforeDraw: (chart: any) => {
            if (image.complete) {
              const ctx = chart.ctx;
              ctx.drawImage(image, 0, 0, chart.width, chart.height);

              const coeff = image.naturalWidth / chart.width;
              if (imageState.some((el: string) => el !== "Original")) {
                if (imageState.some((el: string) => el === "Heatmap")) {
                  dataClusters
                    .map((el: any) => el.clusters)
                    .flat()
                    .forEach((item: any) => {
                      // const x = chart.scales.x.getPixelForValue(point.x); // doesn`t properly work
                      // const y = chart.scales.y.getPixelForValue(point.y); // doesn`t properly work
                      const xItem = item.x * coefWidth; // change coef for view pins on heatmap
                      const yItem = item.y * coefHeight; // change coef for view pins on heatmap
                      const gradient = ctx.createRadialGradient(
                        xItem,
                        yItem,
                        0,
                        xItem,
                        yItem,
                        (item.radius / coeff) * 1.1 // for more border gradient
                      );
                      gradient.addColorStop(0, "rgba(255, 0, 0, 0.8)");
                      gradient.addColorStop(0.5, "rgba(255, 255, 0, 0.5)");
                      gradient.addColorStop(1, "rgba(0, 255, 0, 0)");
                      ctx.beginPath();
                      ctx.arc(
                        xItem,
                        yItem,
                        item.radius / coeff,
                        0,
                        Math.PI * 2
                      );
                      ctx.fillStyle = gradient;
                      ctx.fill();
                      ctx.closePath();
                    });
                }
                if (imageState.some((el: string) => el === "Pins")) {
                  chart.data.datasets.forEach((dataset: any) => {
                    let sortPins =
                      selectedNicknames.length > 0
                        ? dataset.data.filter((obj: any) =>
                            selectedNicknames.includes(obj.user.id)
                          )
                        : dataset.data;
                    sortPins =
                      selectedReactions.length > 0
                        ? sortPins.filter((obj: any) =>
                            selectedReactions.includes(obj.reaction.id)
                          )
                        : sortPins;
                    sortPins.forEach((point: any) => {
                      // const x = chart.scales.x.getPixelForValue(point.x); // doesn`t properly work
                      // const y = chart.scales.y.getPixelForValue(point.y); // doesn`t properly work
                      const x = point.x * coefWidth; // change coef for view pins on heatmap
                      const y = point.y * coefHeight; // change coef for view pins on heatmap

                      ctx.beginPath();
                      ctx.arc(x, y - 0.7, 15, 0, Math.PI * 2);
                      ctx.fillStyle =
                        point.reaction.emotionalTone === 50
                          ? "#66BD22"
                          : point.reaction.emotionalTone === 0
                          ? "#FFD600"
                          : point.reaction.emotionalTone === -50
                          ? "#FF480E"
                          : "white";
                      ctx.fill();
                      ctx.closePath();

                      ctx.font = "700 14px Arial";
                      ctx.fillStyle = "white";
                      ctx.textAlign = "center";
                      ctx.textBaseline = "middle";
                      ctx.fillText(point.previewId, x, y);

                      if (pinsRef.current.includes(point.previewId)) {
                        ctx.beginPath();
                        ctx.arc(x, y, 15, 0, Math.PI * 2);
                        ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
                        ctx.fill();
                        ctx.lineWidth = 2;
                        ctx.strokeStyle = "blue";
                        ctx.stroke();
                        ctx.closePath();
                      }
                    });
                  });
                }
              } else {
                ctx.drawImage(image, 0, 0, chart.width, chart.height);
              }
            }
          },
        };

        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy();
        }

        const chartInstance = new Chart(ctx as any, {
          type: "scatter",
          data: {
            datasets: [
              {
                data: dataPoints,
                pointStyle: "circle",
                pointRadius: 15,
                backgroundColor: "rgba(0, 0, 0, 0)",
                borderColor: "rgba(0, 0, 0, 0)",
                hoverBackgroundColor: "rgba(0, 0, 0, 0)",
                hoverBorderColor: "rgba(0, 0, 0, 0)",
              },
            ],
          },
          options: {
            scales: {
              x: {
                display: false,
              },
              y: {
                display: false,
                reverse: true,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          },
          plugins: [backgroundImagePlugin],
        });

        chartInstanceRef.current = chartInstance;

        chartRef.current.addEventListener("click", handleChartClick);
      };
    }
  };

  const handleChartClick = useCallback(
    (event: any) => {
      const chartInstance = chartInstanceRef.current;
      const points = chartInstance.getElementsAtEventForMode(
        event,
        "nearest",
        { intersect: true },
        false
      );

      if (points.length) {
        const firstPoint = points[0];
        const datasetIndex = firstPoint.datasetIndex;
        const index = firstPoint.index;
        const clickedPoint =
          chartInstance.data.datasets[datasetIndex].data[index];

        setPins((prevPins) => {
          const newPins = prevPins.includes(clickedPoint.previewId)
            ? prevPins.filter((pin) => pin !== clickedPoint.previewId)
            : [...prevPins, clickedPoint.previewId];

          pinsRef.current = newPins;
          handleClickForPins(newPins, sectionId);
          return newPins;
        });
      }
    },
    [handleClickForPins, sectionId]
  );

  useEffect(() => {
    // if (imageState.some((el: string) => el !== "Original")) {
    initializeChart();
    // }
    return () => {
      if (chartRef.current) {
        chartRef.current.removeEventListener("click", handleChartClick);
      }
    };
  }, [imageUrl, dataPoints, imageState, selectedNicknames, selectedReactions]);

  const resetSelectionPins = () => {
    handleClickForPins([]);
    setPins([]);
    pinsRef.current = [];
    if (chartInstanceRef.current) {
      chartInstanceRef.current.update();
    }
  };
  return (
    <div className="heatmap-image board-content-alignfull">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div className="actions-container top-left">
          {btnName.map((item: string, index: number) => (
            <Button
              style={{
                display: "flex",
                alignItems: "center",
              }}
              type="primary"
              key={index}
              onClick={() => handleClickStateImage(item)}
              icon={
                item === "Pins" ? (
                  <img src={pins_circle} alt="camera" />
                ) : item === "Heatmap" ? (
                  <img src={mouse_circle} alt="camera" />
                ) : (
                  <img src={photoCamera} alt="camera" />
                )
              }
            >
              {item}
            </Button>
          ))}
        </div>

        <div className="actions-container top-right">
          {/* <Button type="primary" icon={<CameraOutlined />}></Button> */}
          <Button type="primary" onClick={resetSelectionPins}>
            Reset Selection
          </Button>
        </div>
      </div>
      {imageState.some(
        (el: string) => el === "Heatmap" || el === "Pins" || "Original"
      ) && (
        <canvas
          ref={chartRef}
          style={{
            maxWidth: "1350px",
            maxHeight: "772px",
            width: "100%",
            height: "100%",
          }}
        ></canvas>
      )}
    </div>
  );
};

export default HeatmapWithImage;
