import React, { useCallback, useState, useEffect, useMemo } from "react";
import { Text } from "@visx/text";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import Comments from "Components/Comments/Comments";
import TextToggle from "../TextToggle/TextToggle";
import { useParams } from "react-router-dom";
import { Empty } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getKeyPhraseReport } from "store/slices/keyPhraseReport/keyPhraseReport";
import {
  getKeyPhraseMessage,
  clearMessage,
} from "store/slices/keyPhraseReport/keyPhraseMessage";

import { ConfigProvider, Segmented, Tooltip } from "antd";

const colors = [
  "#143059",
  "#2F6B9A",
  "#82a6c2",
  "#17BECF",
  "#9467BD",
  "#D62728",
  "#FF7F0E",
  "#E377C2",
];

const CloudWord = React.memo(() => {
  const [arrow, setArrow] = useState("Show");

  const mergedArrow = useMemo(() => {
    if (arrow === "Hide") {
      return false;
    }

    if (arrow === "Show") {
      return true;
    }

    return {
      pointAtCenter: true,
    };
  }, [arrow]);
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  const board = useSelector((state: any) => state.board.board);
  const keyPhrase = useSelector(
    (state: any) => state.keyPhraseReport.keyPhrase
  );

  const keyPhraseMessage = useSelector(
    (state: any) => state.keyPhraseMessage.keyPhraseMessage
  );

  const respondents = useSelector(
    (state: any) => state.respondents.respondents
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (record: any) => {
        const name = respondents.find((item: any) => item?.id === record?.id);
        return <>{name ? name.firstName + " " + name.lastName : ""}</>;
      },
    },
    {
      title: "Message",
      dataIndex: "body",
      key: "body",
      render: (record: any) => <TextToggle text={record} />,
    },
  ];

  useEffect(() => {
    if (board.name && keyPhrase.length === 0) {
      dispatch(getKeyPhraseReport(Number(id)));
    }
  }, []);

  const [selectedWord, setSelectedWord] = useState<number | null>(null);
  function getRotationDegree(datum: any, index: number) {
    return index % 2 === 0 ? 0 : 90;
  }

  const modifiedArray = keyPhrase.map((obj: any) => {
    return {
      ...obj,
      value: obj.occurrences,
    };
  });

  const fixedValueGenerator = () => 0.5;

  const onClick = useCallback(
    (w: any) => {
      if (selectedWord !== w.id) {
        dispatch(getKeyPhraseMessage({ id: id ? Number(id) : 0, keyId: w.id }));
        setSelectedWord(w.id);
      } else {
        dispatch(clearMessage([]));
        setSelectedWord(null);
      }
    },
    [selectedWord]
  );

  const maxValue = Math.max(...modifiedArray.map((word: any) => word.value));
  const maxFontSize = 50;

  const fontSize = useCallback(
    (word: any) => {
      const percent = word.value / maxValue;
      return Math.max(maxFontSize * percent, 15);
    },
    [keyPhrase]
  );

  return (
    <div className="board-set">
      <section className="board">
        <header className="board-header">Key Words and Phrases</header>
        <div className="board-body display-flex justify-content-center">
          {modifiedArray.length > 0 ? (
            <Wordcloud
              words={modifiedArray}
              width={600}
              height={600}
              fontSize={fontSize}
              padding={6}
              spiral={"archimedean"}
              rotate={getRotationDegree}
              random={fixedValueGenerator}
            >
              {(cloudWords) =>
                cloudWords.map((w: any, i: any) => (
                  <ConfigProvider key={i}>
                    <Segmented
                      value={arrow}
                      options={["Show", "Hide", "Center"]}
                      onChange={(val: string) => setArrow(val)}
                      style={{ marginBottom: 24 }}
                    />
                    <Tooltip
                      placement="top"
                      title={w.text + " " + `(${w.value})`}
                      arrow={mergedArrow}
                    >
                      <Text
                        style={{ cursor: "pointer" }}
                        key={w.text}
                        fill={
                          selectedWord === w.id
                            ? colors[i % colors.length]
                            : selectedWord
                            ? "gray"
                            : colors[i % colors.length]
                        }
                        textAnchor={"middle"}
                        transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                        fontSize={w.size}
                        fontFamily={w.font}
                        onClick={() => onClick(w)}
                      >
                        {w.text}
                      </Text>
                    </Tooltip>
                  </ConfigProvider>
                ))
              }
            </Wordcloud>
          ) : (
            <Empty></Empty>
          )}
        </div>
      </section>
      <Comments
        dataSource={keyPhraseMessage}
        columns={columns}
        title={"Key Words and Phrases"}
      />
    </div>
  );
});
export default CloudWord;
