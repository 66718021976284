import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Heatmap {
  board: {
    id: number;
  };
  id: number;
  overallReaction: number;
  respondentsCount: number;
  title: string;
}
interface HeatmapSlice {
  isLoading: boolean;
  heatmapSections: Heatmap[];
  error: string;
  includeQuestions: {
    sectionIds: number[];
  };
  includeUsers: {
    ids: number[];
  };
}

const initialState: HeatmapSlice = {
  isLoading: false,
  heatmapSections: [],
  error: "",
  includeQuestions: {
    sectionIds: [],
  },
  includeUsers: {
    ids: [],
  },
};

export const getHeatmapSections = createAsyncThunk(
  "getHeatmapSections",
  async (id: number, { getState }) => {
    const state = (
      getState() as {
        heatmapSection: HeatmapSlice;
      }
    ).heatmapSection;

    const { includeUsers, includeQuestions } = state;
    try {
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/heatmaps/sections`,
        { includeUsers, includeQuestions }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const heatmapSectionsSlice = createSlice({
  initialState,
  name: "heatmapSections",
  reducers: {
    setIncludeUserHeatmapSection: (state, { payload }) => {
      state.includeUsers.ids = payload;
    },
    setIncludeSectionsHeatmapSection: (state, { payload }) => {
      state.includeQuestions.sectionIds = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHeatmapSections.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHeatmapSections.fulfilled, (state, action) => {
        state.isLoading = false;
        state.heatmapSections = action.payload;
      })
      .addCase(getHeatmapSections.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});

const { actions } = heatmapSectionsSlice;

export const {
  setIncludeUserHeatmapSection,
  setIncludeSectionsHeatmapSection,
} = actions;
