import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface KeyPhraseData {
  id: number;
  text: string;
  occurrences: number;
}
interface KeyPhraseSlice {
  isLoading: boolean;
  keyPhrase: KeyPhraseData[];
  error: string;
}

const initialState: KeyPhraseSlice = {
  isLoading: false,
  keyPhrase: [],
  error: "",
};

export const getKeyPhraseReport = createAsyncThunk(
  "getKeyPhraseReport",
  async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/v1/reports/boards/${id}/key-phrases`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const keyPhraseReportSlice = createSlice({
  initialState,
  name: "keyPhrase",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKeyPhraseReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getKeyPhraseReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.keyPhrase = action.payload;
      })
      .addCase(getKeyPhraseReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
