import React, { useEffect, useState, useCallback } from "react";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatusReport,
  updateStatusLoading,
} from "store/slices/statusReport/statusReportSlice";
import { updateReport } from "store/slices/updateReport/updateReportSlice";
import { useParams } from "react-router-dom";
import { getReactedPositiveComments } from "store/slices/reacted/positiveReactedSlice";
import { getReactedNegativeComments } from "store/slices/reacted/negativeReactedSlice";
import { getReactedQuestionsComments } from "store/slices/reacted/questionsReactedSlice";
import { getSectionsAndQuestions } from "store/slices/sections/sectionsSlice";
import { getRespondents } from "store/slices/respondents/respondentsSlice";
import { getReactionChartCounts } from "store/slices/reactionCharts/reactionChartsCounts";
import { getReactionChartPercents } from "store/slices/reactionCharts/reactionChartsPercents";
import { getEmoji } from "store/slices/emoji/emojiSlice";
import { getPositiveComments } from "store/slices/comments/positiveSlice";
import { getNegativeComments } from "store/slices/comments/negativeSlice";
import { getQuestionsComments } from "store/slices/comments/questionSlice";
import { getSentimentChart } from "store/slices/sentimentCharts/sentimentChart";
import { getKeyPhraseReport } from "store/slices/keyPhraseReport/keyPhraseReport";
import { getRecognizedEntity } from "store/slices/recognizedEntity/recognizedEntity";

const StatusReport = React.memo(() => {
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<any>();
  const board = useSelector((state: any) => state.board.board);
  const statusReport = useSelector((state: any) => state.statusReport);

  const updateDataReport = useCallback(() => {
    dispatch(getReactedPositiveComments(Number(id)));
    dispatch(getReactedNegativeComments(Number(id)));
    dispatch(getReactedQuestionsComments(Number(id)));
    dispatch(getSectionsAndQuestions(Number(id)));
    dispatch(getRespondents(Number(id)));
    dispatch(getReactionChartCounts(Number(id)));
    dispatch(getReactionChartPercents(Number(id)));
    dispatch(getEmoji(Number(id)));
    dispatch(getPositiveComments(Number(id)));
    dispatch(getNegativeComments(Number(id)));
    dispatch(getQuestionsComments(Number(id)));
    dispatch(getSentimentChart(Number(id)));
    dispatch(getKeyPhraseReport(Number(id)));
    dispatch(getRecognizedEntity(Number(id)));
  }, [dispatch, id]);

  useEffect(() => {
    if (board.name) {
      dispatch(getStatusReport(Number(id)));
    }
  }, [board.name, dispatch, id]);

  useEffect(() => {
    if (statusReport.isInProgress && !statusReport.isInProgressLoading) {
      setLoading(true);
      const intervalId = setTimeout(async () => {
        const { payload } = await dispatch(getStatusReport(Number(id)));
        if (!payload.isInProgress) {
          updateDataReport();
        }
      }, 15000);
      return () => clearTimeout(intervalId);
    } else {
      setLoading(false);
    }
  }, [statusReport, dispatch, id]);

  const handleClickUpdateReport = async () => {
    setLoading(true);
    await dispatch(updateReport(Number(id)));
    await dispatch(getStatusReport(Number(id)));
    if (statusReport.isInProgress) {
      dispatch(updateStatusLoading(true));
    }
  };

  return (
    <>
      {statusReport.boardIsEmpty ? (
        ""
      ) : (
        <div style={{ background: "white" }}>
          <Button
            style={{ background: "#66BD228C" }}
            loading={loading}
            className="mb-10 ml-20"
            disabled={
              !statusReport.hasUpdatesSinceLastGeneration &&
              statusReport.newMessageCount === 0 &&
              !loading
            }
            onClick={handleClickUpdateReport}
          >
            {statusReport.isInProgress ? "In Progress" : ""}
            {statusReport.hasSuccessfullyFinishedReport &&
            statusReport.hasUpdatesSinceLastGeneration &&
            !statusReport.isInProgress
              ? "Update Report"
              : ""}
            {!statusReport.hasSuccessfullyFinishedReport &&
            !statusReport.isInProgress &&
            !statusReport.boardIsEmpty
              ? "Create Report"
              : ""}
            {statusReport.newMessageCount > 0
              ? `(${statusReport.newMessageCount})`
              : ""}
            {statusReport.hasUpdatesSinceLastGeneration === false &&
            !statusReport.isInProgress &&
            statusReport.hasSuccessfullyFinishedReport === true
              ? "Report Updated"
              : ""}
          </Button>
          <span>
            {statusReport.isInProgress
              ? "** Uploading your data to AI and preparing the report, it might take several minutes. Thank you"
              : ""}
          </span>
        </div>
      )}
    </>
  );
});

export default StatusReport;
