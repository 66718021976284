import { Button } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const TextToggle = ({ text }: any) => {
  const [expanded, setExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setShowButton(textRef.current.offsetHeight > 48);
    }
  }, [text]);

  const toggleText = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="display-flex" style={{ gap: "8px" }}>
      <div
        style={
          expanded
            ? { maxHeight: "unset", flex: "1" }
            : { maxHeight: "48px", overflow: "hidden", flex: "1" }
        }
      >
        <div ref={textRef} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
      {showButton && (
        <Button
          type="primary"
          icon={expanded ? <UpOutlined /> : <DownOutlined />}
          onClick={toggleText}
        />
      )}
    </div>
  );
};

export default TextToggle;
