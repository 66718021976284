import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Checkbox, MenuProps } from "antd";

const DropDown = React.memo(
  ({
    handleCheckboxChange,
    handleSelectAll,
    data,
    selectedData,
    title,
  }: any) => {
    const [open, setOpen] = useState(false);

    const handleOpenChange = (nextOpen: boolean) => {
      setOpen(nextOpen);
    };

    const items: MenuProps["items"] = [
      {
        key: "All",
        label: (
          <Checkbox
            className="dropdown-row flex-row-reverse"
            checked={selectedData?.length === data?.length}
            onChange={(e) => {
              handleSelectAll(e);
            }}
          >
            {title}
          </Checkbox>
        ),
      },
      ...(data || []).map((item: any, index: any) => ({
        key: index,
        label: (
          <Checkbox
            className="dropdown-row flex-row-reverse"
            checked={selectedData.includes(item.id)}
            onChange={(e) => {
              handleCheckboxChange(e, item);
            }}
          >
            {title === "All Users"
              ? `${item.firstName} ${item.lastName}`
              : title === "All Sections"
              ? item.title
              : item?.name}
          </Checkbox>
        ),
      })),
    ];

    return (
      <Dropdown
        menu={{
          items,
          getPopupContainer: () =>
            document.getElementById("drop-down-id") as HTMLDivElement,
          selectable: true,
          multiple: true,
          onClick: ({ domEvent }) => {
            domEvent.stopPropagation();
          },
        }}
        onOpenChange={handleOpenChange}
        open={open}
        trigger={["click"]}
      >
        <a
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <>
            <span>
              {selectedData?.length > 0 && selectedData?.length !== data?.length
                ? title === "All Users"
                  ? `${
                      data?.find((user: any) => user.id === selectedData[0])
                        ?.firstName || ""
                    } ${
                      data?.find((user: any) => user.id === selectedData[0])
                        ?.lastName || ""
                    }`
                  : title === "All Sections"
                  ? data?.find((item: any) => item.id === selectedData[0])
                      ?.title || ""
                  : data?.find((item: any) => item.id === selectedData[0])
                      ?.name || ""
                : title}
              {selectedData?.length > 1 && selectedData?.length !== data?.length
                ? `, + ${selectedData.length - 1} ${title.toLowerCase()}${
                    selectedData.length - 1 === 1 ? "" : "s"
                  }`
                : ""}
            </span>
            <DownOutlined />
          </>
        </a>
      </Dropdown>
    );
  }
);

export default DropDown;
