import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Board {
  id: number;
}

interface Score {
  positive: number;
  neutral: number;
  negative: number;
  total: number;
}

interface ChartStateData {
  board: Board;
  score: Score;
}

interface SentimentChartState {
  isLoading: boolean;
  sentimentChart: ChartStateData[];
  includeUsers: {
    ids: number[];
  };
  excludeUsers: {
    ids: number[];
  };
  error: string;
}

const initialState: SentimentChartState = {
  isLoading: false,
  sentimentChart: [],
  includeUsers: {
    ids: [],
  },
  excludeUsers: {
    ids: [],
  },
  error: "",
};

export const getSentimentChart = createAsyncThunk(
  "getSentimentChart",
  async (id: number, { getState }) => {
    try {
      const state = (getState() as { sentimentChart: SentimentChartState })
        .sentimentChart;
      const { includeUsers } = state;
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/sentiment/chart`,
        { includeUsers }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const sentimentChartSlice = createSlice({
  initialState,
  name: "sentimentChart",
  reducers: {
    setIncludeUserSentimentChart: (state, { payload }) => {
      state.includeUsers.ids = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSentimentChart.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSentimentChart.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sentimentChart = action.payload.data;
      })
      .addCase(getSentimentChart.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});

const { actions } = sentimentChartSlice;

export const { setIncludeUserSentimentChart } = actions;
