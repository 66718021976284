import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ColorBar from "../ColorBar/ColorBar";
import { Empty } from "antd";

const SentimentByQuestion = ({ chartSections, title }: any) => {
  const [sections, setSections] = useState([]);
  const sectionsAndQuestions = useSelector(
    (state: any) => state.sectionsAndQuestions.sectionsAndQuestions
  );

  const questions = useSelector(
    (state: any) => state.sectionsAndQuestions.questions
  );

  useEffect(() => {
    if (chartSections && chartSections.data) {
      const filteredData = chartSections.data.map((item: any) => {
        const { score } = item;
        const filteredScore = Object.entries(score)
          .filter(([key]) => key !== "total")
          .reduce((acc: any, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {});

        return {
          score: filteredScore,
          category: title === "sections" ? item.section : item.question,
        };
      });

      setSections(filteredData);
    }
  }, [chartSections, title]);

  return (
    <section className="board">
      <header className="board-header">Sentiment by Question</header>
      <div className="board-body">
        {sections.length > 0 ? (
          <div className="sentiment-by-question text-neutral-darkest-color">
            {sections.map((item: any, key: number) => {
              return (
                <React.Fragment key={key}>
                  <div>
                    {title === "sections"
                      ? sectionsAndQuestions.find(
                          (name: any) => name.id === item.category.id
                        )?.title
                      : questions.find(
                          (name: any) => name.id === item.category.id
                        )?.title}
                  </div>
                  <div key={key}>
                    <ColorBar
                      totalLength={
                        item?.score?.positive +
                        item?.score?.neutral +
                        item?.score?.negative
                      }
                      color1Length={item?.score?.positive}
                      color2Length={item?.score?.neutral}
                      color3Length={item?.score?.negative}
                    />
                  </div>
                </React.Fragment>
              );
            })}
            <div className="column-scale">
              <span>0</span>
              <span>20%</span>
              <span>40%</span>
              <span>60%</span>
              <span>80%</span>
              <span>100%</span>
            </div>
          </div>
        ) : (
          <Empty></Empty>
        )}
      </div>
    </section>
  );
};

export default SentimentByQuestion;
