import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Empty } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import { getRecognizedEntity } from "store/slices/recognizedEntity/recognizedEntity";

const RecognisedEntities: React.FC = () => {
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  const [collapsedData, setCollapsedData] = useState<any>({});
  const recognizedEntity = useSelector(
    (state: any) => state.recognizedEntity.recognizedEntity
  );
  const board = useSelector((state: any) => state.board.board);

  useEffect(() => {
    if (board.name && recognizedEntity.length === 0) {
      dispatch(getRecognizedEntity(Number(id)));
    }
  }, [board.name]);

  useEffect(() => {
    const collapseByCategory = (arr: any[]) => {
      const collapsedData: any = {};
      arr.forEach((obj) => {
        if (collapsedData[obj.category]) {
          collapsedData[obj.category].push(obj);
        } else {
          collapsedData[obj.category] = [obj];
        }
      });
      return collapsedData;
    };

    if (recognizedEntity) {
      const collapsedData = collapseByCategory(recognizedEntity);
      setCollapsedData(collapsedData);
    }
  }, [recognizedEntity]);

  return (
    <>
      <Menu mode="inline" className="collapse-menu">
        {recognizedEntity.length > 0 ? (
          <>
            {Object.keys(collapsedData).map((category) => (
              <Menu.SubMenu key={category} title={category}>
                {collapsedData[category].map((obj: any) => (
                  <Menu.Item key={obj.id}>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {obj.text}
                      {obj.url ? (
                        <a target="_blank" rel="noreferrer" href={obj.url}>
                          <LinkOutlined
                            style={{
                              transform: "rotate(45deg)",
                              fontSize: "1.3em",
                            }}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                    </span>
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            ))}
          </>
        ) : (
          <Empty></Empty>
        )}
      </Menu>
    </>
  );
};

export default RecognisedEntities;
