import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface SentimentScore {
  positive: number;
  neutral: number;
  negative: number;
  total: number;
}

interface Comment {
  body: string;
  createOn: Date;
  id: number;
  question: {
    id: number;
  };
  replyCount: number;
  sentimentScore: SentimentScore;
  user: {
    id: number;
  };
}
interface CommentsSlice {
  isLoading: boolean;
  negativeComments: Comment[];
  error: string;
}

const initialState: CommentsSlice = {
  isLoading: false,
  negativeComments: [],
  error: "",
};

export const getNegativeComments = createAsyncThunk(
  "getNegativeComments",
  async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/v1/reports/boards/${id}/sentiment/negative-comments`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const negativeCommentsSlice = createSlice({
  initialState,
  name: "board",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNegativeComments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNegativeComments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.negativeComments = action.payload;
      })
      .addCase(getNegativeComments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
