import React, { useState } from "react";
import { Collapse } from "antd";
import HeatmapWithImage from "Components/HeatmapWithImage/HeatmapWithImage";
import { Button, Form, Popover } from "antd";
import DropDown from "Components/DropDown/DropDown";
import { useSelector, useDispatch } from "react-redux";
import { getHeatmapPins } from "store/slices/heatmapReport/heatmapPins";
import { getHeatmapClusters } from "store/slices/heatmapReport/heatmapClusters";
import Comments from "Components/Comments/Comments";
import TextToggle from "../TextToggle/TextToggle";
import { Link } from "react-router-dom";
import {
  EllipsisOutlined,
  EyeOutlined,
  // LikeOutlined
} from "@ant-design/icons";
import { useParams } from "react-router-dom";

const CollapseQuestions = React.memo(({ dataQuestion }: any) => {
  const [selectedNicknames, setSelectedNicknames] = useState<{
    [key: string]: any[];
  }>({});
  const [selectedReactions, setSelectedReactions] = useState<{
    [key: string]: any[];
  }>({});
  const [fetchedKeys, setFetchedKeys] = useState<string[]>([]);
  const [activePins, setActivePins] = useState<{ [key: string]: number[] }>({});

  const { id } = useParams();

  const heatmapPins = useSelector(
    (state: any) => state.heatmapPins.heatmapPins
  );
  const clustersPins = useSelector(
    (state: any) => state.heatmapClusters.heatmapClusters
  );
  const dispatch = useDispatch<any>();
  const heatmapRespondents = useSelector(
    (state: any) => state.heatmapRespondents.respondents
  );

  const actionButtonPopoverTpl = (record: any) => (
    <Link to={record} target="_blank">
      <Button icon={<EyeOutlined />}>View</Button>
    </Link>
  );

  const handleClickForPins = (sectionId: string, pins: number[]) => {
    setActivePins((prevActivePins) => ({
      ...prevActivePins,
      [sectionId]: pins,
    }));
  };

  const columns = [
    {
      title: "PIN №",
      dataIndex: "previewId",
      key: "previewId",
      render: (record: any) => {
        const reaction = heatmapPins.find(
          (pin: any) => pin.previewId === record
        )?.reaction;
        const emotionalTone = reaction ? reaction.emotionalTone : "";
        return (
          <div
            className={`pin-number ${
              emotionalTone === -50
                ? "negative"
                : emotionalTone === 0
                ? "neutral"
                : emotionalTone === 50
                ? "positive"
                : ""
            }`}
          >
            {record}
          </div>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (record: any) => {
        const name = heatmapRespondents.find(
          (item: any) => item?.id === record?.id
        );
        return <>{name ? name.firstName + " " + name.lastName : ""}</>;
      },
    },
    {
      title: "Answer",
      dataIndex: "body",
      key: "body",
      render: (record: any) => <TextToggle text={record} />,
    },
    {
      title: "Reaction",
      dataIndex: "reaction",
      key: "reaction",
      render: (record: any) => {
        return (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div
              className={`vl-badge default ${
                record?.emotionalTone === -50
                  ? "negative"
                  : record?.emotionalTone === 0
                  ? "neutral"
                  : record?.emotionalTone === 50
                  ? "positive"
                  : ""
              }`}
            >
              {String.fromCodePoint(record?.code ? record?.code : "") +
                " " +
                record?.name}
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "url",
      key: "actions",
      render: (record: any) => (
        <Popover content={() => actionButtonPopoverTpl(record)} trigger="click">
          <Button type="primary" icon={<EllipsisOutlined />}></Button>
        </Popover>
      ),
    },
  ];

  const itemsSections = dataQuestion.map((data: any, index: number) => ({
    key: String(index),
    id: data.id,
    image: data.imageUrl,
    reactions: data.reactions,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "72px",
        }}
      >
        <h2>{data.title}</h2>
        <div>
          {/* {data?.overallReaction ? (
            <span className="vl-badge default positive">
              <LikeOutlined />
              Positive Reactions Overall ({data?.overallReaction}%)
            </span>
          ) : (
            ""
          )} */}
        </div>
      </div>
    ),
  }));

  const resetFilters = (sectionId: string) => {
    setSelectedNicknames((prev) => ({ ...prev, [sectionId]: [] }));
    setSelectedReactions((prev: any) => ({ ...prev, [sectionId]: [] }));
  };

  const handleCheckboxChangeReactions = (
    e: any,
    id: any,
    sectionId: string
  ) => {
    const isChecked = e.target.checked;
    setSelectedReactions((prevSelectedReactions: any) => {
      const sectionReactions = prevSelectedReactions[sectionId] || [];
      const newSelectedReactions = isChecked
        ? [...sectionReactions, id.id]
        : sectionReactions.filter((el: any) => el !== id.id);
      return {
        ...prevSelectedReactions,
        [sectionId]: newSelectedReactions,
      };
    });
  };

  const handleSelectAllReactions = (e: any, sectionId: string) => {
    const isChecked = e.target.checked;
    setSelectedReactions((prevSelectedReactions) => ({
      ...prevSelectedReactions,
      [sectionId]: isChecked
        ? dataQuestion
            .find((section: any) => section.id === sectionId)
            ?.reactions.map((item: any) => item.reaction.id) || []
        : [],
    }));
  };

  const handleCheckboxChange = (e: any, nickname: any, sectionId: string) => {
    const isChecked = e.target.checked;
    setSelectedNicknames((prevSelectedNicknames) => {
      const sectionNicknames = prevSelectedNicknames[sectionId] || [];
      const newSelectedNicknames = isChecked
        ? [...sectionNicknames, nickname.id]
        : sectionNicknames.filter((el: any) => el !== nickname.id);
      return {
        ...prevSelectedNicknames,
        [sectionId]: newSelectedNicknames,
      };
    });
  };

  const handleSelectAll = (e: any, sectionId: string) => {
    const isChecked = e.target.checked;
    setSelectedNicknames((prevSelectedNicknames) => {
      const relatedUserIds = heatmapPins
        .filter((pin: any) => pin.sectionId === sectionId)
        .map((pin: any) => pin.user.id);

      const uniqueUserIds = Array.from(new Set(relatedUserIds));

      return {
        ...prevSelectedNicknames,
        [sectionId]: isChecked ? uniqueUserIds : [],
      };
    });
  };

  const onChange = (key: any) => {
    const newKeys = key.filter((key: string) => !fetchedKeys.includes(key));
    newKeys.forEach((key: string) => {
      const item = dataQuestion[Number(key)];
      if (item) {
        dispatch(
          getHeatmapPins({
            id: Number(id),
            sectionId: item.id,
          })
        );
        dispatch(
          getHeatmapClusters({
            id: Number(id),
            sectionId: item.id,
          })
        );
      }
    });
    setFetchedKeys((prevFetchedKeys) => [...prevFetchedKeys, ...newKeys]);
  };

  const filterHeatmapPinsByNicknames = (pins: any, sectionId: string) => {
    if (
      !selectedNicknames[sectionId] ||
      !Array.isArray(selectedNicknames[sectionId]) ||
      selectedNicknames[sectionId].length === 0
    ) {
      return pins;
    }

    return pins.filter((pin: any) =>
      selectedNicknames[sectionId].includes(pin.user.id)
    );
  };

  const filterHeatmapPinsByReactions = (pins: any, sectionId: string) => {
    if (
      !selectedReactions[sectionId] ||
      !Array.isArray(selectedReactions[sectionId]) ||
      selectedReactions[sectionId].length === 0
    ) {
      return pins;
    }

    return pins.filter((pin: any) =>
      selectedReactions[sectionId].includes(pin.reaction.id)
    );
  };

  return (
    <Collapse onChange={onChange} className="custom-collapse-panel">
      {itemsSections.map((item: any, index: number) => (
        <Collapse.Panel header={item.label} key={index}>
          <div
            style={{
              marginLeft: "5px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                color: "#939393EB",
                fontSize: "14px",
                marginBottom: "8px",
              }}
            >
              Heatmap Activity
            </span>
          </div>
          <HeatmapWithImage
            imageUrl={item.image}
            dataPoints={heatmapPins.filter(
              (pin: any) => pin.sectionId === item.id
            )}
            dataClusters={clustersPins.filter(
              (cluster: any) => cluster.questionId === item.id
            )}
            handleClickForPins={(pins: any) =>
              handleClickForPins(item.id, pins)
            }
            selectedNicknames={selectedNicknames[item.id] || []}
            selectedReactions={selectedReactions[item.id] || []}
          />
          <div className="filters" style={{ padding: "24px 0" }}>
            <Form name="basic" layout="vertical">
              <Form.Item<any> label="Respondent" name="respondent">
                <DropDown
                  title={"All Users"}
                  handleCheckboxChange={(e: any, nickname: any) =>
                    handleCheckboxChange(e, nickname, item.id)
                  }
                  handleSelectAll={(e: any) => handleSelectAll(e, item.id)}
                  data={heatmapRespondents.filter((respondent: any) =>
                    heatmapPins
                      .filter((pin: any) => pin.sectionId === item.id)
                      .map((pin: any) => pin.user.id)
                      .includes(respondent.id)
                  )}
                  selectedData={selectedNicknames[item.id] || []}
                />
              </Form.Item>
              <Form.Item<any> label="Reaction" name="Reaction">
                <DropDown
                  title={"All Reactions"}
                  handleCheckboxChange={(e: any, id: any) =>
                    handleCheckboxChangeReactions(e, id, item.id)
                  }
                  handleSelectAll={(e: any) =>
                    handleSelectAllReactions(e, item.id)
                  }
                  data={item.reactions.map(
                    (reaction: any) => reaction.reaction
                  )}
                  selectedData={selectedReactions[item.id] || []}
                />
              </Form.Item>
            </Form>
            <div>
              <Button onClick={() => resetFilters(item.id)}>
                Reset Filters
              </Button>
            </div>
          </div>
          <div>
            {`Results: ${
              activePins[item.id]?.length > 0
                ? activePins[item.id].toString()
                : "All"
            }`}
          </div>
          <div className="display-flex">
            <Comments
              dataSource={filterHeatmapPinsByReactions(
                filterHeatmapPinsByNicknames(
                  activePins[item.id]?.length > 0
                    ? heatmapPins
                        .filter((pin: any) => pin.sectionId === item.id)
                        .filter((obj: any) =>
                          activePins[item.id].includes(obj.previewId)
                        )
                    : heatmapPins.filter(
                        (pin: any) => pin.sectionId === item.id
                      ),
                  item.id
                ),
                item.id
              )}
              columns={columns}
              title={""}
            />
          </div>
        </Collapse.Panel>
      ))}
    </Collapse>
  );
});

export default CollapseQuestions;
