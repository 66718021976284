import React, { useState, useEffect } from "react";
import CloudWord from "Components/CloudWord/CloudWord";
import { Button, Popover, Progress, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getPositiveComments } from "store/slices/comments/positiveSlice";
import { getNegativeComments } from "store/slices/comments/negativeSlice";
import { getQuestionsComments } from "store/slices/comments/questionSlice";
import Comments from "Components/Comments/Comments";
import StatisticBoard from "Components/StatisticBoard/StatisticBoard";
import TextToggle from "../TextToggle/TextToggle";
import RecognisedEntities from "../RecognisedEntities/RecognisedEntities";
import { useParams, Link } from "react-router-dom";
import { EllipsisOutlined, EyeOutlined } from "@ant-design/icons";
import {
  getSentimentChart,
  setIncludeUserSentimentChart,
} from "store/slices/sentimentCharts/sentimentChart";
import {
  getSentimentChartSections,
  setIncludeUser,
} from "store/slices/sentimentCharts/sentimentChartSections";
// import { setIncludeUser } from "store/slices/sentimentCharts/sentimentChartSections";
import { getRespondents } from "store/slices/respondents/respondentsSlice";
import { getSectionsAndQuestions } from "store/slices/sections/sectionsSlice";
import {
  setIncludeUserSentimentQuestions,
  setIncludeSectionsSentimentQuestions,
} from "store/slices/sentimentCharts/sentimentChartQuestions";
import { getSentimentChartQuestions } from "store/slices/sentimentCharts/sentimentChartQuestions";

const TextAnalysis = () => {
  const [selectedNicknames, setSelectedNicknames] = useState<any>([]);
  const [selectedSections, setSelectedSections] = useState<any>([]);
  const [sectionsData, setSectionsData] = useState<any>([]);
  const [respondentsData, setRespondentsData] = useState<any>([]);
  const board = useSelector((state: any) => state.board.board);

  const sentimentChart = useSelector(
    (state: any) => state.sentimentChart.sentimentChart
  );
  const sentimentChartSections = useSelector(
    (state: any) => state.sentimentChartSections.sentimentChartSections
  );
  const sentimentChartQuestions = useSelector(
    (state: any) => state.sentimentChartQuestions.sentimentChartQuestions
  );

  useEffect(() => {
    if (board.name) {
      dispatch(getSentimentChart(Number(id)));
      dispatch(getSentimentChartSections(Number(id)));
    }
  }, [board.name]);

  const sectionsAndQuestions = useSelector(
    (state: any) => state.sectionsAndQuestions.sectionsAndQuestions
  );

  useEffect(() => {
    if (board.name && sectionsAndQuestions.length === 0) {
      dispatch(getSectionsAndQuestions(Number(id)));
    }
    setSectionsData(sectionsAndQuestions);
  }, [board.name, sectionsAndQuestions]);
  const respondents = useSelector(
    (state: any) => state.respondents.respondents
  );

  useEffect(() => {
    if (board.name && respondents.length === 0) {
      dispatch(getRespondents(Number(id)));
    }
    setRespondentsData(respondents);
  }, [board.name, respondents]);

  const handleCheckboxChange = (e: any, nickname: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedNicknames = [...selectedNicknames, nickname.id];
      setSelectedNicknames(newSelectedNicknames);
      dispatch(setIncludeUser(newSelectedNicknames));
      dispatch(setIncludeUserSentimentQuestions(newSelectedNicknames));
      dispatch(setIncludeUserSentimentChart(newSelectedNicknames));
      dispatch(getSentimentChart(Number(id)));

      if (selectedSections.length !== 0) {
        dispatch(getSentimentChartQuestions(Number(id)));
      } else {
        dispatch(setIncludeUser(newSelectedNicknames));
        dispatch(getSentimentChartSections(Number(id)));
      }
    } else {
      const newSelectedNicknames = selectedNicknames.filter(
        (el: any) => el !== nickname.id
      );
      if (selectedSections.length !== 0) {
        dispatch(getSentimentChartQuestions(Number(id)));
      } else {
        dispatch(setIncludeUser(newSelectedNicknames));
        dispatch(getSentimentChartSections(Number(id)));
      }
      setSelectedNicknames(newSelectedNicknames);
      dispatch(setIncludeUser(newSelectedNicknames));
      dispatch(setIncludeUserSentimentQuestions(newSelectedNicknames));
      dispatch(setIncludeUserSentimentChart(newSelectedNicknames));
      dispatch(getSentimentChart(Number(id)));
    }
  };

  const handleCheckboxChangeSections = (e: any, nickname: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const newSelectedSections = [...selectedSections, nickname.id];
      setSelectedSections(newSelectedSections);
      dispatch(setIncludeSectionsSentimentQuestions(newSelectedSections));
      dispatch(setIncludeUserSentimentQuestions(selectedNicknames));
      dispatch(getSentimentChartQuestions(Number(id)));
    } else {
      const newSelectedSections = selectedSections.filter(
        (el: any) => el !== nickname.id
      );
      setSelectedSections(newSelectedSections);
      dispatch(setIncludeSectionsSentimentQuestions(newSelectedSections));
      dispatch(setIncludeUserSentimentQuestions(selectedNicknames));
      dispatch(getSentimentChartQuestions(Number(id)));
    }
  };

  const handleSelectAll = (e: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const respondentIds = respondents.map((respondent: any) => respondent.id);
      setSelectedNicknames(respondentIds);
      dispatch(setIncludeUser(respondentIds));
      dispatch(setIncludeUserSentimentQuestions(respondentIds));
      dispatch(setIncludeUserSentimentChart(respondentIds));
      dispatch(getSentimentChart(Number(id)));

      if (selectedSections.length !== 0) {
        dispatch(getSentimentChartQuestions(Number(id)));
      } else {
        dispatch(setIncludeUser(respondentIds));
        dispatch(getSentimentChartSections(Number(id)));
      }
    } else {
      setSelectedNicknames([]);
      dispatch(setIncludeUser([]));
      dispatch(setIncludeUserSentimentQuestions([]));
      dispatch(setIncludeUserSentimentChart([]));
      dispatch(getSentimentChart(Number(id)));

      if (selectedSections.length !== 0) {
        dispatch(getSentimentChartQuestions(Number(id)));
      } else {
        dispatch(getSentimentChartSections(Number(id)));
      }
    }
  };

  const handleSelectAllSections = (e: any) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const sectionsId = sectionsAndQuestions.map(
        (respondent: any) => respondent.id
      );
      setSelectedSections(sectionsId);
      dispatch(setIncludeUser(sectionsId));
      dispatch(setIncludeSectionsSentimentQuestions(sectionsId));
      setIncludeUserSentimentQuestions(selectedNicknames);
      dispatch(getSentimentChartQuestions(Number(id)));
    } else {
      setSelectedSections([]);
      dispatch(setIncludeSectionsSentimentQuestions([]));
      setIncludeUserSentimentQuestions(selectedNicknames);
      dispatch(getSentimentChartQuestions(Number(id)));
    }
  };

  const dispatch = useDispatch<any>();

  const actionButtonPopoverTpl = (record: any) => (
    <Link to={record} target="_blank">
      <Button icon={<EyeOutlined />}>View</Button>
    </Link>
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "user",
      key: "user",
      render: (record: any) => {
        const name = respondents.find((item: any) => item?.id === record?.id);
        return <>{name ? name.firstName + " " + name.lastName : ""}</>;
      },
    },
    {
      title: "Message",
      dataIndex: "body",
      key: "body",
      render: (record: any) => <TextToggle text={record} />,
    },
    {
      title: "Reply count",
      dataIndex: "replyCount",
      key: "replyCount",
    },
    {
      title: "Avg Sentiment Score",
      dataIndex: "sentimentScore",
      key: "sentimentScore",
      render: (record: any) => (
        <Progress
          percent={record?.total * 100}
          format={(percent: any) => `${parseInt(percent)}%`}
          strokeColor={"#66BD228C"}
          trailColor={"transparent"}
          strokeLinecap="butt"
          size={[120, 24]}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "url",
      key: "actions",
      render: (record: any) => (
        <Popover content={() => actionButtonPopoverTpl(record)} trigger="click">
          <Button
            type="primary"
            icon={<EllipsisOutlined />}
            // onClick={() => handleAction(record)}
          ></Button>
        </Popover>
      ),
    },
  ];
  const negativeColumns = columns.map((column) => {
    if (column.key === "sentimentScore") {
      return {
        ...column,
        render: (record: any) => (
          <Progress
            percent={record?.total * 100}
            format={(percent: any) => `${parseInt(percent)}%`}
            strokeColor={"#FF480E8C"}
            trailColor={"#ddd"}
            strokeLinecap="butt"
            size={[120, 24]}
          />
        ),
      };
    }
    return column;
  });
  // const questionsColumns = columns.map((column) => {
  //   if (column.key === "sentimentScore") {
  //     return {
  //       ...column,
  //       render: (record: any) => (
  //         <Progress
  //           percent={record?.neutral * 100}
  //           format={(percent: any) => `${parseInt(percent)}%`}
  //           strokeColor={"#FFD600"}
  //           trailColor={"#ddd"}
  //           strokeLinecap="butt"
  //           size={[120, 24]}
  //         />
  //       ),
  //     };
  //   }
  //   return column;
  // });

  const questionsColumns = [
    {
      title: "Message",
      dataIndex: "body",
      key: "body",
      render: (record: any) => <TextToggle text={record} />,
    },
    {
      title: "Reply Count",
      dataIndex: "replyCount",
      key: "replyCount",
      render: (record: any) => (
        <span style={{ marginLeft: "30px" }}>{record}</span>
      ),
    },
    {
      title: "Avg Reaction Score",
      dataIndex: "reactionScore",
      key: "reactionScore",
      render: (record: any) => (
        <Progress
          style={{ marginLeft: "30px" }}
          percent={record * 100}
          format={(percent: any) => `${parseInt(percent)}%`}
          strokeColor={"#FFD600"}
          trailColor={"#ddd"}
          strokeLinecap="butt"
          size={[120, 24]}
        />
      ),
    },
    {
      title: "Actions",
      dataIndex: "url",
      key: "actions",
      render: (record: any) => (
        <Popover content={() => actionButtonPopoverTpl(record)} trigger="click">
          <Button
            type="primary"
            icon={<EllipsisOutlined />}
            // onClick={() => handleAction(record)}
          ></Button>
        </Popover>
      ),
    },
  ];
  const positiveComments = useSelector(
    (state: any) => state.positiveComments.positiveComments
  );
  const negativeComments = useSelector(
    (state: any) => state.negativeComments.negativeComments
  );
  const controversialQuestions = useSelector(
    (state: any) => state.controversialQuestions.questions
  );

  const { id } = useParams();

  useEffect(() => {
    if (board.name) {
      if (positiveComments.length === 0) {
        dispatch(getPositiveComments(Number(id)));
      }
      if (negativeComments.length === 0) {
        dispatch(getNegativeComments(Number(id)));
      }
      if (controversialQuestions.length === 0) {
        dispatch(getQuestionsComments(Number(id)));
      }
    }
  }, [board.name]);

  const [activeTab, setActiveTab] = useState<number>(1);

  const checkActiveTab = (value: any) => {
    setActiveTab(Number(value));
  };

  const resetFilters = () => {
    setSelectedSections([]);
    setSelectedNicknames([]);
    dispatch(setIncludeSectionsSentimentQuestions([]));
    dispatch(setIncludeUser([]));
    dispatch(setIncludeUserSentimentQuestions([]));
    dispatch(setIncludeUserSentimentChart([]));
    // dispatch(getSentimentChartQuestions(Number(id)));
    dispatch(getSentimentChartSections(Number(id)));
    dispatch(getSentimentChart(Number(id)));
  };

  const analysisTabs = [
    {
      key: "1",
      label: "Sentiment",
      children: (
        <StatisticBoard
          tab={"sentiment"}
          sectionsData={sectionsData}
          respondentsData={respondentsData}
          handleCheckboxChange={handleCheckboxChange}
          handleCheckboxChangeSections={handleCheckboxChangeSections}
          handleSelectAll={handleSelectAll}
          handleSelectAllSections={handleSelectAllSections}
          chart={sentimentChart}
          chartSections={sentimentChartSections}
          chartQuestions={sentimentChartQuestions}
          selectedSections={selectedSections}
          selectedNicknames={selectedNicknames}
          resetFilters={resetFilters}
          pieChartTitle="Overall Respondent Sentiment"
        />
      ),
    },
    {
      key: "2",
      label: "Key Words",
      children: <CloudWord />,
    },
    {
      key: "3",
      label: "Recognised Entities",
      children: "",
    },
  ];
  return (
    <>
      <Tabs
        defaultActiveKey="0"
        onChange={checkActiveTab}
        className={"tabs-as-buttons"}
        items={analysisTabs}
      />
      {/* {activeTab === 1 ? <button>Export PDF</button> : null} */}
      {activeTab === 1 ? (
        <div className="board-set" style={{ paddingTop: "0" }}>
          <>
            <Comments
              dataSource={positiveComments.map((comment: any) => ({
                ...comment,
                key: comment.id.toString(),
              }))}
              columns={columns}
              title={"Top-10 comments with positive sentiment"}
            />
            <Comments
              dataSource={negativeComments.map((comment: any) => ({
                ...comment,
                key: comment.id.toString(),
              }))}
              columns={negativeColumns}
              title={"Top-10 comments with negative sentiment"}
            />
            <Comments
              dataSource={controversialQuestions.map((comment: any) => ({
                ...comment,
                key: comment.id.toString(),
              }))}
              columns={questionsColumns}
              title={"Most controversial questions"}
            />
          </>
        </div>
      ) : (
        ""
      )}
      {activeTab === 3 ? (
        <div className="board-set">
          <section className="board">
            <header className="board-header">Recognised Entities</header>
            <div className="board-body">
              <RecognisedEntities />
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default TextAnalysis;
