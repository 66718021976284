import { createSlice } from "@reduxjs/toolkit";

interface Notification {
  showNotification: boolean;
  message: string;
}

const initialState: Notification = {
  showNotification: false,
  message: "",
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showNotification(state, action) {
      state.showNotification = true;
      state.message = action.payload;
    },
    hideNotification(state) {
      state.showNotification = false;
      state.message = "";
    },
  },
});

export const { showNotification, hideNotification } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
