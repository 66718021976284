import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface StatusReport {
  isLoading: boolean;
  boardId: number | null;
  hasSuccessfullyFinishedReport: boolean | null;
  isInProgress: boolean | null;
  hasUpdatesSinceLastGeneration: boolean | null;
  newMessageCount: number | null;
  boardIsEmpty: boolean | null;
  error: string;
  isInProgressLoading: boolean;
}

const initialState: StatusReport = {
  isLoading: false,
  boardId: null,
  hasSuccessfullyFinishedReport: null,
  isInProgress: null,
  hasUpdatesSinceLastGeneration: null,
  newMessageCount: null,
  boardIsEmpty: null,
  error: "",
  isInProgressLoading: false,
};

export const getStatusReport = createAsyncThunk(
  "getStatusReport",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/v1/boards/${id}/status`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const statusReportSlice = createSlice({
  initialState,
  name: "statusReport",
  reducers: {
    updateStatusLoading: (state, { payload }) => {
      state.isInProgressLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStatusReport.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStatusReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.boardId = action.payload.boardId;
        state.boardIsEmpty = action.payload.boardIsEmpty;
        state.hasSuccessfullyFinishedReport =
          action.payload.hasSuccessfullyFinishedReport;
        state.hasUpdatesSinceLastGeneration =
          action.payload.hasUpdatesSinceLastGeneration;
        state.isInProgress = action.payload.isInProgress;
        state.newMessageCount = action.payload.newMessageCount;
      })
      .addCase(getStatusReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});

const { actions } = statusReportSlice;

export const { updateStatusLoading } = actions;
