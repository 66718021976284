import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Reaction {
  id: number;
  code: string;
  name: string;
  emotionalTone: number;
}

interface User {
  id: number;
}

interface Pin {
  answerId: number;
  messageId: number;
  reaction: Reaction;
  x: number;
  y: number;
  body: string;
  url: string;
  createdOn: string;
  user: User;
}

interface Pins {
  isLoading: boolean;
  heatmapPins: Pin[];
  activePins: number[];
  error: string;
}

const initialState: Pins = {
  isLoading: false,
  heatmapPins: [],
  activePins: [],
  error: "",
};

interface getHeatmapPinsParams {
  id: number;
  sectionId: number;
}

export const getHeatmapPins = createAsyncThunk(
  "getHeatmapPins",
  async (params: getHeatmapPinsParams) => {
    const { id, sectionId } = params;
    try {
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/heatmaps/${sectionId}/pins`,
        {}
      );

      response.data = response.data.map((item: any) => {
        item.sectionId = sectionId;
        return item;
      });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const heatmapPinsSlice = createSlice({
  initialState,
  name: "heatmapPins",
  reducers: {
    setActivePins: (state, { payload }) => {
      state.activePins.push(payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHeatmapPins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHeatmapPins.fulfilled, (state, action) => {
        state.isLoading = false;
        const addPreviewId = action.payload
          .sort((pinOne: Pin, pinTwo: Pin) => pinOne.answerId - pinTwo.answerId)
          .map((pin: Pin, index: number) => ({
            ...pin,
            previewId: index + 1,
          }));

        state.heatmapPins.push(...addPreviewId);
      })
      .addCase(getHeatmapPins.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
const { actions } = heatmapPinsSlice;

export const { setActivePins } = actions;
