import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Section {
  id: number;
}

interface Question {
  id: number;
  body: string;
  title: string;
  position: number;
  questionType: number;
  answerType: number;
}

interface Board {
  id: number;
}

interface SectionsSliceData {
  board: Board;
  id: number;
  questions: Question[];
  section: Section;
}

interface SectionsSlice {
  isLoading: boolean;
  sectionsAndQuestions: SectionsSliceData[];
  error: string;
  questions: any;
}

const initialState: SectionsSlice = {
  isLoading: false,
  sectionsAndQuestions: [],
  error: "",
  questions: [],
};

export const getSectionsAndQuestions = createAsyncThunk(
  "getSection/getQuestions",
  async (id: number) => {
    try {
      const [sectionsResponse, questionsResponse] = await Promise.all([
        axiosInstance.get(
          `/v1/boards/${id}/sections`
        ),
        axiosInstance.get(
          `/v1/boards/${id}/questions`
        ),
      ]);
      const sectionsWithQuestions = sectionsResponse.data.map(
        (section: any) => {
          return {
            ...section,
            questions: questionsResponse.data.filter(
              (question: any) => question.section.id === section.id
            ),
          };
        }
      );

      return sectionsWithQuestions;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const sectionsAndQuestions = createSlice({
  initialState,
  name: "board",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSectionsAndQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSectionsAndQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sectionsAndQuestions = action.payload;
        const allQuestions = action.payload.reduce(
          (acc: any[], section: any) => {
            return [...acc, ...section.questions];
          },
          []
        );
        state.questions = allQuestions;
      })
      .addCase(getSectionsAndQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
