import React from "react";
import { Collapse } from "antd";
import CollapseQuestions from "Components/CollapseQuestions/CollapseQuestions";
import {
  // LikeOutlined,
  MessageOutlined,
} from "@ant-design/icons";

const CollapseForHeatmap = ({ heatmapData, heatmapQuestionsData }: any) => {
  const itemsSections = heatmapData.map((data: any, index: number) => ({
    key: String(index),
    id: data.id,
    label: (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "72px",
        }}
      >
        <h2>{data.title}</h2>
        <div className="vl-badge-group">
          {/* {data?.overallReaction ? (
            <span className="vl-badge default positive">
              <LikeOutlined />
              Positive Reactions Overall ({data?.overallReaction}%)
            </span>
          ) : (
            ""
          )} */}
          {data?.respondentsCount ? (
            <span className="vl-badge default grey">
              <MessageOutlined />
              {data?.respondentsCount} Respondents
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    ),
  }));

  return (
    <div className="board">
      <Collapse className="custom-collapse-panel">
        {itemsSections.map((item: any, index: number) => (
          <Collapse.Panel header={item.label} key={index}>
            <CollapseQuestions
              dataQuestion={heatmapQuestionsData.filter(
                (qustionItem: any) => qustionItem.section.id === item.id
              )}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default CollapseForHeatmap;
