import React from "react";

interface ColorBarProps {
  totalLength: number;
  color1Length: number;
  color2Length: number;
  color3Length: number;
}

const ColorBar: React.FC<ColorBarProps> = ({
  totalLength,
  color1Length,
  color2Length,
  color3Length,
}) => {
  const calculateWidth = (length: number) => {
    return `${(length / totalLength) * 100}%`;
  };

  return (
    <div className="stacked-bar-graph">
      <div
        className="stacked-bar-graph-item"
        style={{
          width: calculateWidth(color1Length),
          backgroundColor: "rgba(102, 189, 34, 0.55)",
        }}
      >
        {Math.round(color1Length * 100)}
      </div>
      <div
        className="stacked-bar-graph-item"
        style={{
          width: calculateWidth(color2Length),
          backgroundColor: "rgba(255, 214, 0, 0.55)",
        }}
      >
        {Math.round(color2Length * 100)}
      </div>
      <div
        className="stacked-bar-graph-item"
        style={{
          width: calculateWidth(color3Length),
          backgroundColor: "rgba(255, 72, 14, 0.55)",
        }}
      >
        {Math.round(color3Length * 100)}
      </div>
    </div>
  );
};

export default ColorBar;
