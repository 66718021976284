import React, { useEffect } from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { hideNotification } from "store/slices/notifications/notifications";

type NotificationType = "success" | "info" | "warning" | "error";

const Notification: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const showNotification = useSelector((state: any) => state.notifications);

  const dispatch = useDispatch();

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: "Error",
      duration: 5,
      description: showNotification.message,
      onClose: () => {
        dispatch(hideNotification());
      },
    });
  };

  useEffect(() => {
    if (showNotification.showNotification) {
      openNotificationWithIcon("error");
    }
  }, [showNotification]);

  return <>{contextHolder}</>;
};

export default Notification;
