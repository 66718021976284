import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

interface Tab {
  key: number | string;
  label: string;
  children: React.ReactNode | string;
}

interface TabsInterface extends Array<Tab> {}

const TabContent: React.FC<{
  items: TabsInterface;
  checkActiveTab?: (value: any) => void;
  className?: string;
}> = ({ items, checkActiveTab, className }) => {
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (checkActiveTab) {
      checkActiveTab(activeTab);
    }
  }, [activeTab]);

  const handleChange = (key: string) => {
    setActiveTab(Number(key));
  };

  const tabItems = items.map((item) => ({
    key: item.key.toString(),
    label: item.label,
    children: item.children,
  }));

  return (
    <>
      <Tabs
        defaultActiveKey="0"
        onChange={handleChange}
        className={className}
        items={tabItems}
      />
    </>
  );
};

export default TabContent;
