import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface AnswerPayload {
  answerIds: number[];
  x: number | null;
  y: number | null;
  radius: number | null;
}
interface heatmapClusters {
  clusters: AnswerPayload[];
  questionId: number;
}

interface Clusters {
  isLoading: boolean;
  heatmapClusters: heatmapClusters[];
  error: string;
}

const initialState: Clusters = {
  isLoading: false,
  heatmapClusters: [],
  error: "",
};

interface getHeatmapPinsParams {
  id: number;
  sectionId: number;
}

export const getHeatmapClusters = createAsyncThunk(
  "getHeatmapClusters",
  async (params: getHeatmapPinsParams) => {
    const { id, sectionId } = params;
    try {
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/heatmaps/${sectionId}/clusters`,
        {}
      );

      // response.data = response.data.map((item: any) => {
      //   item.sectionId = sectionId;
      //   return item;
      // });
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);
export const heatmapClustersSlice = createSlice({
  initialState,
  name: "heatmapPins",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHeatmapClusters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHeatmapClusters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.heatmapClusters.push(action.payload);
      })
      .addCase(getHeatmapClusters.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
