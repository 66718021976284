import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface Section {
  id: number;
}

interface Score {
  positive: number;
  neutral: number;
  negative: number;
  total: number;
}

interface SentimentChartData {
  section: Section;
  score: Score;
}

interface SentimentChartState {
  isLoading: boolean;
  sentimentChartQuestions: SentimentChartData[];
  includeUsers: {
    ids: number[];
  };
  includeQuestions: {
    sectionIds: number[];
    // questionIds: any[];
  };
  excludeUsers: {
    ids: number[];
  };
  error: string;
}

const initialState: SentimentChartState = {
  isLoading: false,
  sentimentChartQuestions: [],
  includeUsers: {
    ids: [],
  },
  includeQuestions: {
    sectionIds: [],
    // questionIds: [],
  },
  excludeUsers: {
    ids: [],
  },
  error: "",
};

export const getSentimentChartQuestions = createAsyncThunk(
  "sentimentChartQuestions/getSentimentChartQuestions",
  async (id: number, { getState }) => {
    try {
      const state = (
        getState() as { sentimentChartQuestions: SentimentChartState }
      ).sentimentChartQuestions;
      const { includeUsers, includeQuestions } = state;
      const response = await axiosInstance.post(
        `/v1/reports/boards/${id}/sentiment/chart/questions`,
        { includeUsers, includeQuestions }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const sentimentChartQuestionsSlice = createSlice({
  initialState,
  name: "sentimentChartQuestions",
  reducers: {
    setIncludeUserSentimentQuestions: (state, { payload }) => {
      state.includeUsers.ids = payload;
    },
    setIncludeSectionsSentimentQuestions: (state, { payload }) => {
      state.includeQuestions.sectionIds = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSentimentChartQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSentimentChartQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sentimentChartQuestions = action.payload;
      })
      .addCase(getSentimentChartQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
const { actions } = sentimentChartQuestionsSlice;

export const {
  setIncludeUserSentimentQuestions,
  setIncludeSectionsSentimentQuestions,
} = actions;
