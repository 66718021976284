import React, { useEffect } from "react";
import TabContent from "Components/Tabs/TabContent";
import TextAnalysis from "Components/TextAnalysis/TextAnalysis";
import ResponsesAnalysis from "Components/ResponsesAnalysis/ResponsesAnalysis";
import Heatmap from "Components/Heatmap/Heatmap";
import { useDispatch, useSelector } from "react-redux";
import { getBoard } from "store/slices/board/boardSlice";
import { getCurrentUser } from "store/slices/user/currentUserSlice";
import { useParams } from "react-router-dom";

const analysisTabs = [
  {
    key: 1,
    label: "Text Analysis",
    children: <TextAnalysis />,
  },
  {
    key: 2,
    label: "Responses Analysis",
    children: <ResponsesAnalysis />,
  },
  {
    key: 3,
    label: "Heatmap",
    children: <Heatmap />,
  },
];

const Boards = () => {
  const board = useSelector((state: any) => state.board.board);
  const currentUser = useSelector((state: any) => state.currentUser.user);

  const dispatch = useDispatch<any>();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getBoard(Number(id)));
  }, []);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  return (
    <div>
      <header className="welcome-header">
        <span>
          Welcome, {currentUser?.firstName} {currentUser?.lastName}
        </span>
        <b>Reports and Transcripts</b>
        <b>Board: {board?.name}</b>
      </header>
      <TabContent items={analysisTabs} className={"tabs-as-buttons"} />
    </div>
  );
};

export default Boards;
