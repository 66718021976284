import React from "react";
import PieChart from "Components/PieChart/PieChart";
import ReactionBar from "Components/ReactionBar/ReactionBar";
import SentimentByQuestion from "Components/SentimentByQuestion/SentimentByQuestion";
import DropDown from "Components/DropDown/DropDown";
import StatusReport from "Components/StatusReport/StatusReport";
import { Button, Form, Empty } from "antd";
import ExportPdf from "Components/ExportPdf/ExportPdf";
interface TabProps {
  tab: string;
  sectionsData?: any;
  respondentsData?: any;
  handleCheckboxChange?: any;
  handleCheckboxChangeSections?: any;
  handleSelectAll?: any;
  handleSelectAllSections?: any;
  chart?: any;
  chartSections?: any;
  chartQuestions?: any;
  selectedSections?: any;
  selectedNicknames?: any;
  reactionsCounts?: any;
  resetFilters?: any;
  pieChartTitle?: any;
}
const StatisticBoard = React.memo(
  ({
    tab,
    sectionsData,
    respondentsData,
    handleCheckboxChange,
    handleCheckboxChangeSections,
    handleSelectAll,
    handleSelectAllSections,
    chart,
    chartSections,
    chartQuestions,
    selectedSections,
    selectedNicknames,
    reactionsCounts,
    resetFilters,
    pieChartTitle,
  }: TabProps) => {
    return (
      <>
        {tab === "sentiment" ? (
          <div className="filters">
            <Form name="basic" layout="vertical">
              <Form.Item<any> label="View" name="view">
                {/* <DropDownWithCollapse title={"All sections and questions"} /> */}
                <DropDown
                  title={"All Sections"}
                  handleCheckboxChange={handleCheckboxChangeSections}
                  handleSelectAll={handleSelectAllSections}
                  data={sectionsData}
                  selectedData={selectedSections}
                />
              </Form.Item>
              <Form.Item<any> label="Respondent" name="respondent">
                <DropDown
                  title={"All Users"}
                  handleCheckboxChange={handleCheckboxChange}
                  handleSelectAll={handleSelectAll}
                  data={respondentsData}
                  selectedData={selectedNicknames}
                />
              </Form.Item>
            </Form>
            <div>
              <Button onClick={resetFilters}>Reset Filters</Button>
            </div>
          </div>
        ) : null}
        <div style={{ display: "flex", background: "white" }}>
          <StatusReport />
          <ExportPdf />
        </div>

        <div className="board-set">
          <div className="board-group">
            {tab === "responsesAnalysis" ? (
              <ReactionBar reactionsCounts={reactionsCounts} />
            ) : (
              <SentimentByQuestion
                chartSections={
                  selectedSections.length === 0 ? chartSections : chartQuestions
                }
                title={selectedSections.length === 0 ? "sections" : "questions"}
              />
            )}
            <section className="board">
              <header className="board-header">{pieChartTitle}</header>
              <div className="board-body display-flex justify-content-center">
                {chart.length > 0 ? (
                  <PieChart chart={chart} tab={tab} />
                ) : (
                  <Empty></Empty>
                )}
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
);

export default StatisticBoard;
