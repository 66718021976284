import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const PieChart = ({ chart, tab }: any) => {
  const [chartData, setChartData] = useState<any>({
    datasets: [
      {
        data: [],
        backgroundColor: ["#FF480E8C", "#FFD6008C", "#66BD228C"],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    if (chart && tab === "sentiment") {
      const filteredData = chart.map((item: any) => {
        const { score } = item;
        return { score: [score.negative, score.neutral, score.positive] };
      });

      const updateData = filteredData.map((item: any) =>
        Object.values(item.score).map((score: any) => Math.round(score * 100))
      );

      setChartData((prevChartData: any) => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: updateData.flat(),
          },
        ],
      }));
    }

    if (chart && tab === "responsesAnalysis") {
      const updateData = chart.map((item: any) => Math.round(item.value * 100));

      setChartData((prevChartData: any) => ({
        ...prevChartData,
        datasets: [
          {
            ...prevChartData.datasets[0],
            data: updateData.flat(),
            backgroundColor: [
              "#288C458C",
              "#66BD228C",
              "#FFD6008C",
              "#FF480E8C",
              "#BD00068C",
            ],
          },
        ],
      }));
    }
  }, [chart]);

  const options: any = {
    plugins: {
      datalabels: {
        formatter: function (value: any) {
          const val = Math.round(value);
          return new Intl.NumberFormat("tr-TR").format(val) + "%";
        },
        color: "white",

        font: {
          weight: "bold",
          size: 14,
          family: "poppins",
        },
      },
      responsive: true,
    },
  };

  return (
    <div style={{ width: 300, height: 300 }}>
      <Doughnut data={chartData} options={options} />
    </div>
  );
};

export default PieChart;
