import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Boards from "Pages/Boards/Boards";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/boards/:id" element={<Boards />} />
      <Route path="/" element={<Navigate to="/boards" />} />
      <Route path="/boards" element={<Boards />} />
      <Route path="*" element={<div>NOT FOUND</div>} />
    </Routes>
  );
};

export default AppRouter;
