import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "utils/api";

interface RecognizedEntityData {
  id: number;
  text: string;
  category: string;
  confidenceScore: string;
  language: string;
  url: string;
}

interface KeyPhraseSlice {
  isLoading: boolean;
  recognizedEntity: RecognizedEntityData[];
  error: string;
}

const initialState: KeyPhraseSlice = {
  isLoading: false,
  recognizedEntity: [],
  error: "",
};

export const getRecognizedEntity = createAsyncThunk(
  "getRecognizedEntity",
  async (id: number) => {
    try {
      const response = await axiosInstance.get(
        `/v1/reports/boards/${id}/recognized-entities`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

export const recognizedEntitySlice = createSlice({
  initialState,
  name: "recognizedEntity",
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecognizedEntity.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRecognizedEntity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.recognizedEntity = action.payload;
      })
      .addCase(getRecognizedEntity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message as string;
      });
  },
});
