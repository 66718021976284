// import { configureStore } from "@reduxjs/toolkit";
import { Middleware, configureStore, isRejected } from "@reduxjs/toolkit";
import { respondentsSlice } from "store/slices/respondents/respondentsSlice";
import { boardSlice } from "store/slices/board/boardSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { positiveCommentsSlice } from "store/slices/comments/positiveSlice";
import { negativeCommentsSlice } from "store/slices/comments/negativeSlice";
import { quetsionsSlice } from "store/slices/comments/questionSlice";
import { sectionsAndQuestions } from "store/slices/sections/sectionsSlice";
import { reactedPositiveCommentsSlice } from "store/slices/reacted/positiveReactedSlice";
import { reactedNegativeCommentsSlice } from "store/slices/reacted/negativeReactedSlice";
import { reactedQuetsionsSlice } from "store/slices/reacted/questionsReactedSlice";
import { sentimentChartSlice } from "store/slices/sentimentCharts/sentimentChart";
import { sentimentChartSectionsSlice } from "store/slices/sentimentCharts/sentimentChartSections";
import { sentimentChartQuestionsSlice } from "store/slices/sentimentCharts/sentimentChartQuestions";
import { reactionChartsCountsSlice } from "store/slices/reactionCharts/reactionChartsCounts";
import { reactionChartsPercentsSlice } from "store/slices/reactionCharts/reactionChartsPercents";
import { emojiSlice } from "store/slices/emoji/emojiSlice";
import { keyPhraseReportSlice } from "store/slices/keyPhraseReport/keyPhraseReport";
import { keyPhraseMessageSlice } from "store/slices/keyPhraseReport/keyPhraseMessage";
import { recognizedEntitySlice } from "store/slices/recognizedEntity/recognizedEntity";
import {
  notificationsSlice,
  showNotification,
} from "store/slices/notifications/notifications";
import { statusReportSlice } from "store/slices/statusReport/statusReportSlice";
import { currentUserSlice } from "store/slices/user/currentUserSlice";
import { updateReportSlice } from "./slices/updateReport/updateReportSlice";
import { heatmapSectionsSlice } from "./slices/heatmapReport/heatmapSections";
import { heatmapQuestionsSlice } from "./slices/heatmapReport/heatmapQuestions";
import { heatmapPinsSlice } from "./slices/heatmapReport/heatmapPins";
import { heatmapClustersSlice } from "./slices/heatmapReport/heatmapClusters";
import { heatmapRespondentsSlice } from "./slices/heatmapReport/heatmapRespondents";
import { exportPdfSlice } from "./slices/exportPdf/exportPdf";

export const rtkQueryErrorLogger: Middleware =
  () => (next) => (action: any) => {
    if (isRejected(action)) {
      if (action?.error?.message === "Request failed with status code 401") {
        next(showNotification("Invalid ID"));
      } else {
        next(
          showNotification(
            action?.payload?.response?.data === ""
              ? "Invalid ID"
              : action?.payload?.response?.data?.Message
          )
        );
      }
    }

    return next(action);
  };

export const store = configureStore({
  reducer: {
    respondents: respondentsSlice.reducer,
    board: boardSlice.reducer,
    positiveComments: positiveCommentsSlice.reducer,
    negativeComments: negativeCommentsSlice.reducer,
    controversialQuestions: quetsionsSlice.reducer,
    sectionsAndQuestions: sectionsAndQuestions.reducer,
    reactedPositiveComments: reactedPositiveCommentsSlice.reducer,
    reactedNegativeComments: reactedNegativeCommentsSlice.reducer,
    reactedQuetsionsComments: reactedQuetsionsSlice.reducer,
    reactedChartsCounts: reactionChartsCountsSlice.reducer,
    reactionChartsPercents: reactionChartsPercentsSlice.reducer,
    emoji: emojiSlice.reducer,
    sentimentChart: sentimentChartSlice.reducer,
    sentimentChartSections: sentimentChartSectionsSlice.reducer,
    sentimentChartQuestions: sentimentChartQuestionsSlice.reducer,
    keyPhraseReport: keyPhraseReportSlice.reducer,
    keyPhraseMessage: keyPhraseMessageSlice.reducer,
    recognizedEntity: recognizedEntitySlice.reducer,
    notifications: notificationsSlice.reducer,
    currentUser: currentUserSlice.reducer,
    statusReport: statusReportSlice.reducer,
    updateReport: updateReportSlice.reducer,
    heatmapSection: heatmapSectionsSlice.reducer,
    heatmapQuestions: heatmapQuestionsSlice.reducer,
    heatmapPins: heatmapPinsSlice.reducer,
    heatmapClusters: heatmapClustersSlice.reducer,
    heatmapRespondents: heatmapRespondentsSlice.reducer,
    exportPdf: exportPdfSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkQueryErrorLogger),
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type RootState = ReturnType<typeof store.getState>;
